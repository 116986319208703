/*!
 * https://getbootstrap.com/docs/4.1/utilities/flex/
 * Bootstrap v4.1.3 flex utilities (https://getbootstrap.com/)
 * Copyright 2011-2018 The Bootstrap Authors
 * Copyright 2011-2018 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
@import url("https://fonts.googleapis.com/css?family=Open+Sans:400,700");
@import url("https://fonts.googleapis.com/css?family=Roboto");
.d-flex {
  display: -ms-flexbox !important;
  display: flex !important; }

.d-inline-flex {
  display: -ms-inline-flexbox !important;
  display: inline-flex !important; }

.flex-row {
  -ms-flex-direction: row !important;
  flex-direction: row !important; }

.flex-column {
  -ms-flex-direction: column !important;
  flex-direction: column !important; }

.flex-row-reverse {
  -ms-flex-direction: row-reverse !important;
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  -ms-flex-direction: column-reverse !important;
  flex-direction: column-reverse !important; }

.flex-wrap {
  -ms-flex-wrap: wrap !important;
  flex-wrap: wrap !important; }

.flex-nowrap {
  -ms-flex-wrap: nowrap !important;
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  -ms-flex-wrap: wrap-reverse !important;
  flex-wrap: wrap-reverse !important; }

.flex-fill {
  -ms-flex: 1 1 auto !important;
  flex: 1 1 auto !important; }

.flex-grow-0 {
  -ms-flex-positive: 0 !important;
  flex-grow: 0 !important; }

.flex-grow-1 {
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important; }

.flex-shrink-0 {
  -ms-flex-negative: 0 !important;
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  -ms-flex-negative: 1 !important;
  flex-shrink: 1 !important; }

.justify-content-start {
  -ms-flex-pack: start !important;
  justify-content: flex-start !important; }

.justify-content-end {
  -ms-flex-pack: end !important;
  justify-content: flex-end !important; }

.justify-content-center {
  -ms-flex-pack: center !important;
  justify-content: center !important; }

.justify-content-between {
  -ms-flex-pack: justify !important;
  justify-content: space-between !important; }

.justify-content-around {
  -ms-flex-pack: distribute !important;
  justify-content: space-around !important; }

.align-items-start {
  -ms-flex-align: start !important;
  align-items: flex-start !important; }

.align-items-end {
  -ms-flex-align: end !important;
  align-items: flex-end !important; }

.align-items-center {
  -ms-flex-align: center !important;
  align-items: center !important; }

.align-items-baseline {
  -ms-flex-align: baseline !important;
  align-items: baseline !important; }

.align-items-stretch {
  -ms-flex-align: stretch !important;
  align-items: stretch !important; }

.align-content-start {
  -ms-flex-line-pack: start !important;
  align-content: flex-start !important; }

.align-content-end {
  -ms-flex-line-pack: end !important;
  align-content: flex-end !important; }

.align-content-center {
  -ms-flex-line-pack: center !important;
  align-content: center !important; }

.align-content-between {
  -ms-flex-line-pack: justify !important;
  align-content: space-between !important; }

.align-content-around {
  -ms-flex-line-pack: distribute !important;
  align-content: space-around !important; }

.align-content-stretch {
  -ms-flex-line-pack: stretch !important;
  align-content: stretch !important; }

.align-self-auto {
  -ms-flex-item-align: auto !important;
  align-self: auto !important; }

.align-self-start {
  -ms-flex-item-align: start !important;
  align-self: flex-start !important; }

.align-self-end {
  -ms-flex-item-align: end !important;
  align-self: flex-end !important; }

.align-self-center {
  -ms-flex-item-align: center !important;
  align-self: center !important; }

.align-self-baseline {
  -ms-flex-item-align: baseline !important;
  align-self: baseline !important; }

.align-self-stretch {
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important; }

@media (min-width: 576px) {
  .d-sm-flex {
    display: -ms-flexbox !important;
    display: flex !important; }
  .d-sm-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-flex {
    display: -ms-flexbox !important;
    display: flex !important; }
  .d-md-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-flex {
    display: -ms-flexbox !important;
    display: flex !important; }
  .d-lg-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-flex {
    display: -ms-flexbox !important;
    display: flex !important; }
  .d-xl-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important; } }

@media print {
  .d-print-flex {
    display: -ms-flexbox !important;
    display: flex !important; }
  .d-print-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important; } }

@media (min-width: 576px) {
  .flex-sm-row {
    -ms-flex-direction: row !important;
    flex-direction: row !important; }
  .flex-sm-column {
    -ms-flex-direction: column !important;
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important; }
  .flex-sm-fill {
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important; }
  .flex-sm-grow-0 {
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important; }
  .justify-content-sm-start {
    -ms-flex-pack: start !important;
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    -ms-flex-pack: end !important;
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    -ms-flex-pack: center !important;
    justify-content: center !important; }
  .justify-content-sm-between {
    -ms-flex-pack: justify !important;
    justify-content: space-between !important; }
  .justify-content-sm-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important; }
  .align-items-sm-start {
    -ms-flex-align: start !important;
    align-items: flex-start !important; }
  .align-items-sm-end {
    -ms-flex-align: end !important;
    align-items: flex-end !important; }
  .align-items-sm-center {
    -ms-flex-align: center !important;
    align-items: center !important; }
  .align-items-sm-baseline {
    -ms-flex-align: baseline !important;
    align-items: baseline !important; }
  .align-items-sm-stretch {
    -ms-flex-align: stretch !important;
    align-items: stretch !important; }
  .align-content-sm-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important; }
  .align-content-sm-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important; }
  .align-content-sm-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important; }
  .align-content-sm-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important; }
  .align-content-sm-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important; }
  .align-content-sm-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important; }
  .align-self-sm-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important; }
  .align-self-sm-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important; }
  .align-self-sm-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important; }
  .align-self-sm-center {
    -ms-flex-item-align: center !important;
    align-self: center !important; }
  .align-self-sm-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important; }
  .align-self-sm-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    -ms-flex-direction: row !important;
    flex-direction: row !important; }
  .flex-md-column {
    -ms-flex-direction: column !important;
    flex-direction: column !important; }
  .flex-md-row-reverse {
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important; }
  .flex-md-grow-0 {
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important; }
  .justify-content-md-start {
    -ms-flex-pack: start !important;
    justify-content: flex-start !important; }
  .justify-content-md-end {
    -ms-flex-pack: end !important;
    justify-content: flex-end !important; }
  .justify-content-md-center {
    -ms-flex-pack: center !important;
    justify-content: center !important; }
  .justify-content-md-between {
    -ms-flex-pack: justify !important;
    justify-content: space-between !important; }
  .justify-content-md-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important; }
  .align-items-md-start {
    -ms-flex-align: start !important;
    align-items: flex-start !important; }
  .align-items-md-end {
    -ms-flex-align: end !important;
    align-items: flex-end !important; }
  .align-items-md-center {
    -ms-flex-align: center !important;
    align-items: center !important; }
  .align-items-md-baseline {
    -ms-flex-align: baseline !important;
    align-items: baseline !important; }
  .align-items-md-stretch {
    -ms-flex-align: stretch !important;
    align-items: stretch !important; }
  .align-content-md-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important; }
  .align-content-md-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important; }
  .align-content-md-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important; }
  .align-content-md-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important; }
  .align-content-md-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important; }
  .align-content-md-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important; }
  .align-self-md-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important; }
  .align-self-md-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important; }
  .align-self-md-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important; }
  .align-self-md-center {
    -ms-flex-item-align: center !important;
    align-self: center !important; }
  .align-self-md-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important; }
  .align-self-md-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    -ms-flex-direction: row !important;
    flex-direction: row !important; }
  .flex-lg-column {
    -ms-flex-direction: column !important;
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important; }
  .flex-lg-grow-0 {
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important; }
  .justify-content-lg-start {
    -ms-flex-pack: start !important;
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    -ms-flex-pack: end !important;
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    -ms-flex-pack: center !important;
    justify-content: center !important; }
  .justify-content-lg-between {
    -ms-flex-pack: justify !important;
    justify-content: space-between !important; }
  .justify-content-lg-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important; }
  .align-items-lg-start {
    -ms-flex-align: start !important;
    align-items: flex-start !important; }
  .align-items-lg-end {
    -ms-flex-align: end !important;
    align-items: flex-end !important; }
  .align-items-lg-center {
    -ms-flex-align: center !important;
    align-items: center !important; }
  .align-items-lg-baseline {
    -ms-flex-align: baseline !important;
    align-items: baseline !important; }
  .align-items-lg-stretch {
    -ms-flex-align: stretch !important;
    align-items: stretch !important; }
  .align-content-lg-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important; }
  .align-content-lg-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important; }
  .align-content-lg-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important; }
  .align-content-lg-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important; }
  .align-content-lg-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important; }
  .align-content-lg-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important; }
  .align-self-lg-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important; }
  .align-self-lg-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important; }
  .align-self-lg-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important; }
  .align-self-lg-center {
    -ms-flex-item-align: center !important;
    align-self: center !important; }
  .align-self-lg-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important; }
  .align-self-lg-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    -ms-flex-direction: row !important;
    flex-direction: row !important; }
  .flex-xl-column {
    -ms-flex-direction: column !important;
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important; }
  .flex-xl-fill {
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important; }
  .flex-xl-grow-0 {
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important; }
  .justify-content-xl-start {
    -ms-flex-pack: start !important;
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    -ms-flex-pack: end !important;
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    -ms-flex-pack: center !important;
    justify-content: center !important; }
  .justify-content-xl-between {
    -ms-flex-pack: justify !important;
    justify-content: space-between !important; }
  .justify-content-xl-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important; }
  .align-items-xl-start {
    -ms-flex-align: start !important;
    align-items: flex-start !important; }
  .align-items-xl-end {
    -ms-flex-align: end !important;
    align-items: flex-end !important; }
  .align-items-xl-center {
    -ms-flex-align: center !important;
    align-items: center !important; }
  .align-items-xl-baseline {
    -ms-flex-align: baseline !important;
    align-items: baseline !important; }
  .align-items-xl-stretch {
    -ms-flex-align: stretch !important;
    align-items: stretch !important; }
  .align-content-xl-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important; }
  .align-content-xl-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important; }
  .align-content-xl-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important; }
  .align-content-xl-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important; }
  .align-content-xl-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important; }
  .align-content-xl-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important; }
  .align-self-xl-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important; }
  .align-self-xl-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important; }
  .align-self-xl-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important; }
  .align-self-xl-center {
    -ms-flex-item-align: center !important;
    align-self: center !important; }
  .align-self-xl-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important; }
  .align-self-xl-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important; } }

.content-wrapper {
  min-height: calc(100vh - 139px) !important; }

.btn {
  border-radius: 0 !important; }

.error {
  border: 1px solid red;
  border-radius: 4px;
  padding: 2px 10px; }

.text-right {
  text-align: right; }

.margin-top10 {
  margin-top: 10px; }

.bordered-title h4.title {
  text-transform: uppercase;
  font-weight: 700 !important;
  padding-bottom: 10px;
  border-bottom: 1px solid; }

.flag-unmarked {
  color: #e3e3e3;
  cursor: pointer; }

.flag-unmarked:hover {
  color: #acacac; }

.flag-marked {
  color: #cc2127; }

.preformatted-text {
  white-space: pre-wrap; }

.btn i {
  margin: 0 5px; }

.Image-Loader div.Image-Background {
  position: relative; }

.Image-Loader .Image-Background.Image-Not-Found img {
  display: block;
  max-width: 100%; }

.Image-Loader .Image-Background img {
  display: none; }

.Image-Loader .Image-Background.Image-Background-Contain {
  background-size: contain; }

.Image-Loader .Image-Background.Image-Background-Cover {
  background-size: cover; }

.Image-Loader .zoom:hover, .Image-Loader .zoom:focus {
  transform: scale(1.1);
  transition: all 0.5s; }

.Image-Spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); }
  .Image-Spinner .fa {
    width: 1.28571429em; }

.Polaroid-Images {
  text-align: center;
  margin: 15px 0 0;
  display: flex;
  align-items: center;
  justify-content: center; }
  .Polaroid-Images .Image-Loader {
    border: 2px solid #e3e3e3;
    cursor: pointer;
    margin-right: 10px;
    flex: 1;
    height: 95px; }
    .Polaroid-Images .Image-Loader .Image-Background {
      width: 100% !important;
      height: 100% !important;
      background-position: center center !important; }

.text-left {
  text-align: left; }

.text-center {
  text-align: center; }

.text-caps {
  text-transform: capitalize; }

.down-color {
  color: #cc2127 !important; }

.margin-auto {
  margin: 0 auto; }

.inline-block {
  display: inline-block; }

.pointer-events {
  pointer-events: none; }

.margin-overall {
  margin: 20px; }

.Thumbnail-Image {
  cursor: pointer;
  max-width: 300px;
  margin: 0 auto;
  text-align: center; }
  .Thumbnail-Image img {
    max-height: 80px;
    max-width: 160px; }
  .Thumbnail-Image .Image-Caption > div {
    font-size: 14px; }

.block {
  display: block; }

.inline {
  display: inline; }

.alert-sm {
  width: 40%; }

.form-error {
  color: #ff0000; }

.thRow.Sorting-Enabled {
  cursor: pointer; }
  .thRow.Sorting-Enabled .thRow-Inner {
    display: flex;
    align-items: center;
    flex: 1;
    justify-content: center; }
    .thRow.Sorting-Enabled .thRow-Inner i {
      margin-left: 5px;
      font-size: 20px; }
    .thRow.Sorting-Enabled .thRow-Inner .col {
      display: inline-block; }
      .thRow.Sorting-Enabled .thRow-Inner .col.fa-sort-asc {
        padding-top: 4px; }
      .thRow.Sorting-Enabled .thRow-Inner .col.fa-sort-desc {
        margin-bottom: 8px; }

.color-black {
  color: #000000 !important; }

.color-white {
  color: #ffffff !important; }

.color-gray {
  color: #777777; }

.color-green {
  color: #74bc87; }

.color-red {
  color: #ff0000; }

.change-from {
  font-size: 12px; }

.omni__tooltip {
  width: 270px;
  font-size: 13px;
  font-weight: 400;
  text-transform: initial;
  text-align: left;
  border: 1px solid #acacac;
  border-radius: 0px; }

#SettingsView .omni__tooltip {
  width: 450px; }

/* .omni__tooltip--right {
  margin-left: 17px !important;
} */
.place-right:after {
  border-right-color: #acacac !important; }

.Scrollable-Content {
  min-height: 200px;
  max-height: 300px;
  overflow-y: auto; }

.Table-Scrollable {
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse; }
  .Table-Scrollable tbody {
    display: block;
    overflow: auto;
    height: 220px; }
  .Table-Scrollable .delete-contact i {
    color: #de0028;
    cursor: pointer;
    font-size: 18px; }
  .Table-Scrollable tr {
    display: flex; }
    .Table-Scrollable tr td,
    .Table-Scrollable tr th {
      text-align: center;
      flex: 1; }

.modal-backdrop {
  z-index: 1050; }

.contact-form-btn {
  color: #fff;
  font-size: 14px; }

.brand-settings {
  margin: 15px 0px;
  color: #333333;
  font-weight: 300; }

#whyToFlagBtn,
#retailer-contact-btn,
#flagBrandProfileForm__btn {
  color: #000000;
  border: 1px solid lightgray;
  font-weight: bold;
  text-transform: uppercase; }

.flagged-form .user-setting,
.setting-form .user-setting,
#manageCatalogTabs .user-setting {
  padding: 0px 15px !important; }
  .flagged-form .user-setting .export-reports .react-datepicker__header,
  .setting-form .user-setting .export-reports .react-datepicker__header,
  #manageCatalogTabs .user-setting .export-reports .react-datepicker__header {
    padding-bottom: 8px; }
  .flagged-form .user-setting .export-reports .react-datepicker__month,
  .flagged-form .user-setting .export-reports .react-datepicker__header,
  .setting-form .user-setting .export-reports .react-datepicker__month,
  .setting-form .user-setting .export-reports .react-datepicker__header,
  #manageCatalogTabs .user-setting .export-reports .react-datepicker__month,
  #manageCatalogTabs .user-setting .export-reports .react-datepicker__header {
    font-size: 12px; }

.flagged-form .tab-content,
.setting-form .tab-content,
#manageCatalogTabs .tab-content {
  border: 1px solid lightgray !important;
  padding: 15px !important;
  border-top: 0px !important; }
  .flagged-form .tab-content .tab-pane,
  .setting-form .tab-content .tab-pane,
  #manageCatalogTabs .tab-content .tab-pane {
    padding: 0px; }

.flagged-form .nav-tabs li,
.setting-form .nav-tabs li,
#manageCatalogTabs .nav-tabs li {
  padding-left: 0;
  margin-right: 0px !important;
  background: #f5f5f5;
  border-bottom: 1px solid lightgray; }
  @media (max-width: 1400px) {
    .flagged-form .nav-tabs li,
    .setting-form .nav-tabs li,
    #manageCatalogTabs .nav-tabs li {
      margin-right: 6px; } }
  .flagged-form .nav-tabs li a,
  .setting-form .nav-tabs li a,
  #manageCatalogTabs .nav-tabs li a {
    border: 1px solid #ddd;
    border-bottom-color: transparent;
    border-radius: 0;
    margin: 0px;
    margin-bottom: -1px; }
    .flagged-form .nav-tabs li a h4,
    .setting-form .nav-tabs li a h4,
    #manageCatalogTabs .nav-tabs li a h4 {
      margin: 0px;
      font-family: "Roboto", sans-serif;
      font-size: 14px;
      text-transform: uppercase;
      font-weight: 500;
      color: #777777; }
      @media (max-width: 1400px) {
        .flagged-form .nav-tabs li a h4,
        .setting-form .nav-tabs li a h4,
        #manageCatalogTabs .nav-tabs li a h4 {
          font-size: 12px; } }
      .flagged-form .nav-tabs li a h4 span,
      .setting-form .nav-tabs li a h4 span,
      #manageCatalogTabs .nav-tabs li a h4 span {
        text-transform: initial; }
  .flagged-form .nav-tabs li.active a p,
  .setting-form .nav-tabs li.active a p,
  #manageCatalogTabs .nav-tabs li.active a p {
    color: #000000; }
  .flagged-form .nav-tabs li.active a h4,
  .setting-form .nav-tabs li.active a h4,
  #manageCatalogTabs .nav-tabs li.active a h4 {
    color: #000000; }

tr.disabled {
  pointer-events: none;
  opacity: 0.4; }

.appVersion {
  color: #337ab7; }

.email-preview .modal-dialog {
  width: 80%; }

.siteNalysisLogo {
  position: fixed;
  bottom: 0;
  left: 0;
  padding: 15px 0;
  color: #000;
  text-align: center;
  width: 260px; }
  .siteNalysisLogo .siteNalysisLogo-inner {
    width: 190px;
    opacity: 0.4;
    margin: 0 auto; }
    .siteNalysisLogo .siteNalysisLogo-inner img {
      max-width: 100%; }
    .siteNalysisLogo .siteNalysisLogo-inner a {
      color: black !important;
      font-size: 34px !important;
      font-weight: 500 !important; }

.header-right-menu {
  display: flex;
  justify-content: flex-end; }
  .header-right-menu .siteNalysisLogo-header {
    padding: 5px 0;
    color: #000;
    text-align: center;
    width: 225px; }
    .header-right-menu .siteNalysisLogo-header .siteNalysisLogo-inner {
      width: 150px;
      opacity: 0.8;
      margin: 0 auto;
      margin-right: 20px; }
      .header-right-menu .siteNalysisLogo-header .siteNalysisLogo-inner img {
        max-width: 100%; }
    .header-right-menu .siteNalysisLogo-header .siteNalysisLogo-inner-ie {
      width: 160px; }
  .header-right-menu .logout-btn {
    background-color: rgba(112, 112, 112, 0) !important; }
  .header-right-menu .powered-by sub {
    bottom: 0em; }
  .header-right-menu .powered-by-ie sub {
    font-size: 100%; }

.admin-confirm {
  position: absolute;
  z-index: 1;
  height: 100%;
  width: 100%;
  display: block;
  top: 0;
  left: 0;
  background-size: cover;
  background-position: center center; }
  .admin-confirm .card {
    width: 400px;
    left: 50%;
    position: absolute;
    top: 30%;
    min-height: 300px;
    box-shadow: 0px 2px 7px 3px #cfc8c8;
    transform: translateX(-50%); }
    .admin-confirm .card .card-text {
      display: flex;
      justify-content: space-around; }

.login-page {
  padding-top: 4vh;
  position: relative; }
  .login-page .alert-info,
  .login-page .alert-danger {
    text-align: center;
    border-radius: 2px !important;
    font-size: 12px; }
    .login-page .alert-info button.close,
    .login-page .alert-danger button.close {
      position: relative !important;
      right: -15px;
      background-color: transparent !important; }
  .login-page .login-form {
    background: #537e9a;
    width: 35%;
    max-width: 470px;
    position: absolute;
    top: 100px;
    left: 50%;
    transform: translateX(-50%); }
    @media (max-width: 991px) {
      .login-page .login-form {
        width: 100%; } }
    .login-page .login-form .brand-logo {
      background: #fff;
      max-width: 100%;
      padding-top: 20px;
      padding-bottom: 10px; }
      .login-page .login-form .brand-logo h5 {
        margin-top: 0px; }
      .login-page .login-form .brand-logo p {
        font-size: 14px; }
      .login-page .login-form .brand-logo .logo {
        max-width: 250px; }
    .login-page .login-form .card {
      text-transform: uppercase;
      padding-top: 25px;
      border-radius: 15px;
      box-shadow: 0px 2px 7px 3px #cfc8c8;
      margin-bottom: 0; }
      .login-page .login-form .card .content {
        width: 92%;
        margin: auto; }
      .login-page .login-form .card .header {
        padding-top: 0px;
        padding-bottom: 5px; }
        .login-page .login-form .card .header .title a {
          color: black;
          font-size: 34px;
          font-weight: 500; }
    .login-page .login-form .login-loader {
      display: none; }
    .login-page .login-form input {
      border-radius: 0 !important; }
    .login-page .login-form .btn-wd {
      border-radius: 0 !important;
      width: 92% !important;
      text-transform: uppercase;
      opacity: 1;
      font-size: 14px;
      font-weight: 600; }
  .login-page .message-alert {
    width: 320px;
    max-width: 470px;
    cursor: pointer;
    position: absolute;
    top: 30px;
    left: 50%;
    transform: translateX(-50%); }
    @media (max-width: 991px) {
      .login-page .message-alert {
        width: 320px; } }
    @media (max-width: 767px) {
      .login-page .message-alert {
        width: 90%; } }
    .login-page .message-alert .alert-info {
      background-color: #d9edf7;
      color: #31708f;
      border: 1px solid #a7c2cf; }
      .login-page .message-alert .alert-info button {
        color: #31708f;
        opacity: 1; }
    .login-page .message-alert .alert-danger {
      background-color: #ffe6e6;
      color: #ff5151;
      border: 1px solid #eb5d5d; }
      .login-page .message-alert .alert-danger button.close {
        opacity: 1;
        color: #ff5151; }

.error-message {
  color: #ff0000;
  padding: 2px; }

#root .btn-gray {
  background-color: #a9a9a9;
  border: 1px solid #a9a9a9;
  color: #fff;
  text-transform: uppercase;
  font-size: 18px;
  font-weight: 900;
  opacity: 1; }
  #root .btn-gray:hover, #root .btn-gray:focus {
    opacity: 0.7; }

#root .btn-block {
  width: 100%; }

#root .btn-blue {
  background-color: #2e3192;
  border: 1px solid #2e3192;
  color: #fff;
  text-transform: uppercase;
  font-size: 18px;
  font-weight: 900;
  opacity: 1; }
  #root .btn-blue:hover, #root .btn-blue:focus {
    opacity: 0.7; }

#root .btn-blue-inverse {
  border: 1px solid #2e3192;
  color: #2e3192;
  background-color: transparent;
  text-transform: uppercase;
  font-size: 18px;
  font-weight: 900;
  opacity: 1; }
  #root .btn-blue-inverse:hover, #root .btn-blue-inverse:focus {
    opacity: 0.7; }

#root #header .navbar {
  font-size: 14px;
  color: #231f20;
  border-bottom: 1px solid #d1d2e7;
  margin: 0;
  padding: 10px 0; }
  #root #header .navbar .form-heading-mob {
    display: none; }
  @media (max-width: 767px) {
    #root #header .navbar .container-fluid {
      display: block; } }
  #root #header .navbar .container-fluid .navbar-header img {
    max-width: 230px; }
  @media (max-width: 767px) {
    #root #header .navbar .container-fluid .navbar-header {
      display: block; }
      #root #header .navbar .container-fluid .navbar-header img {
        max-width: 210px; } }
  #root #header .navbar .container-fluid .navbar-header .navbar-brand {
    display: flex;
    align-items: center; }
  #root #header .navbar .container-fluid .navbar-collapse {
    flex: inherit; }
    @media (min-width: 992px) {
      #root #header .navbar .container-fluid .navbar-collapse ul li {
        padding: 0px 30px; } }
    #root #header .navbar .container-fluid .navbar-collapse a {
      color: #231f20;
      font-weight: 600; }
    #root #header .navbar .container-fluid .navbar-collapse a:hover {
      color: #231f20;
      font-weight: 600; }
    #root #header .navbar .container-fluid .navbar-collapse .dropdown-toggle {
      border: 1px solid #a9a9a9;
      border-radius: 2px !important;
      background-color: #a9a9a9;
      color: #fff;
      font-size: 14px;
      font-weight: 600;
      width: 100%;
      opacity: 1; }
      #root #header .navbar .container-fluid .navbar-collapse .dropdown-toggle span {
        display: none; }
      #root #header .navbar .container-fluid .navbar-collapse .dropdown-toggle:hover, #root #header .navbar .container-fluid .navbar-collapse .dropdown-toggle:focus {
        opacity: 0.7; }
    #root #header .navbar .container-fluid .navbar-collapse .dropdown ul {
      border-radius: 0;
      padding: 20px;
      margin-top: 15px; }
      #root #header .navbar .container-fluid .navbar-collapse .dropdown ul #login-form {
        width: 300px;
        width: 250px; }
        #root #header .navbar .container-fluid .navbar-collapse .dropdown ul #login-form .form-control {
          border-radius: 0; }
        #root #header .navbar .container-fluid .navbar-collapse .dropdown ul #login-form .form-heading {
          text-align: center; }
        #root #header .navbar .container-fluid .navbar-collapse .dropdown ul #login-form input {
          border-color: #e3e3e3;
          font-size: 16px;
          border-radius: 0; }
        #root #header .navbar .container-fluid .navbar-collapse .dropdown ul #login-form .form-group {
          margin-bottom: 25px; }
          #root #header .navbar .container-fluid .navbar-collapse .dropdown ul #login-form .form-group button {
            margin: 0; }
      #root #header .navbar .container-fluid .navbar-collapse .dropdown ul .form-heading {
        color: #2e3192;
        margin-top: 0px;
        font-size: 1.99rem;
        text-align: center;
        /*  @media (max-width: 767px) {
                    color: #818181;
                  } */ }
      #root #header .navbar .container-fluid .navbar-collapse .dropdown ul .forgot-password-link {
        text-align: right; }
        #root #header .navbar .container-fluid .navbar-collapse .dropdown ul .forgot-password-link span {
          border-bottom: 1px solid #2e3192;
          cursor: pointer;
          color: #2e3192;
          font-weight: 400; }
        #root #header .navbar .container-fluid .navbar-collapse .dropdown ul .forgot-password-link span:hover {
          color: #2e3192; }
    #root #header .navbar .container-fluid .navbar-collapse .dropdown-menu:after,
    #root #header .navbar .container-fluid .navbar-collapse .dropdown-menu:before {
      display: none; }
    #root #header .navbar .container-fluid .navbar-collapse .navbar-nav li {
      padding: 0 15px; }
      @media (max-width: 767px) {
        #root #header .navbar .container-fluid .navbar-collapse .navbar-nav li a {
          padding-left: 0;
          color: #231f20;
          opacity: 1; } }
    #root #header .navbar .container-fluid .navbar-collapse .navbar-nav #login-dropdown-menu {
      padding: 0; }
      #root #header .navbar .container-fluid .navbar-collapse .navbar-nav #login-dropdown-menu .dropdown {
        width: 110px;
        margin-right: 10px; }
        #root #header .navbar .container-fluid .navbar-collapse .navbar-nav #login-dropdown-menu .dropdown .dropdown-menu {
          position: absolute;
          left: auto;
          right: 0; }
    @media (max-width: 767px) {
      #root #header .navbar .container-fluid .navbar-collapse {
        text-align: left;
        color: #231f20; }
        #root #header .navbar .container-fluid .navbar-collapse ul li {
          border-bottom: 1px solid #d1d2e7; }
          #root #header .navbar .container-fluid .navbar-collapse ul li .dropdown-toggle {
            border: 0;
            border-radius: none;
            background-color: transparent;
            color: #231f20;
            font-size: 14px;
            font-weight: 600; }
        #root #header .navbar .container-fluid .navbar-collapse ul li:nth-child(3) {
          border-bottom: none; } }
  @media (min-width: 768px) and (max-width: 992px) {
    #root #header .navbar .navbar-toggle {
      display: none !important; }
    #root #header .navbar .container-fluid .navbar-nav {
      display: flex; }
    #root #header .navbar .container-fluid .navbar-header {
      flex: 1 1; }
    #root #header .navbar .container-fluid .open ul.dropdown-menu {
      position: absolute;
      top: 120%;
      float: left;
      min-width: 160px;
      font-size: 14px;
      text-align: left;
      list-style: none;
      background-color: #fff;
      background-clip: padding-box;
      border: 1px solid rgba(0, 0, 0, 0.15);
      border-radius: 4px;
      -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
      box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175); } }

@media (max-width: 767px) {
  #root #header .navbar-default .navbar-toggle .icon-bar {
    background-color: #2e3192;
    width: 36px;
    height: 4px; } }

#root #footer {
  border-top: 1px solid #d1d2e7;
  padding: 1.2em; }
  #root #footer .footer__text {
    text-align: right;
    font-size: 16px;
    color: #2e3192; }
    @media (max-width: 767px) {
      #root #footer .footer__text {
        text-align: center;
        font-size: 16px; } }

#reset-password .admin-logout {
  display: none; }

#reset-password .reset-password {
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center; }
  #reset-password .reset-password #reset-password-form {
    max-width: 100%; }
    @media (max-width: 767px) {
      #reset-password .reset-password #reset-password-form {
        max-width: 100%; }
        #reset-password .reset-password #reset-password-form .form-heading {
          font-size: 4vw; } }
    #reset-password .reset-password #reset-password-form .reset-password-btn {
      background-color: #2e3192;
      border: 1px solid #2e3192;
      color: #fff;
      width: 100%;
      text-transform: uppercase;
      font-size: 18px;
      font-weight: 900; }
  @media (max-width: 767px) {
    #reset-password .reset-password {
      height: auto; } }

#forgot-password-modal {
  text-align: center;
  padding: 0 !important; }
  @media (max-width: 767px) {
    #forgot-password-modal {
      position: absolute;
      top: 80px; } }
  #forgot-password-modal p {
    text-align: center; }
  #forgot-password-modal #forgot-password__form .form-group {
    margin-bottom: 30px;
    padding: 0 0px 0 0; }
  #forgot-password-modal #forgot-password__form input {
    border-radius: 0px; }
  #forgot-password-modal #forgot-password__submit-btn {
    background-color: #2e3192;
    border: 1px solid #2e3192;
    color: #fff;
    width: 100%;
    text-transform: uppercase;
    font-size: 18px;
    font-weight: 900;
    margin-top: 5px;
    opacity: 1; }
  #forgot-password-modal #contained-modal-title-vcenter {
    color: #818181;
    font-weight: 600;
    font-size: 28px;
    margin-top: 30px; }
  #forgot-password-modal label {
    font-weight: 400; }
    @media (max-width: 767px) {
      #forgot-password-modal label {
        font-size: 14px; } }
  #forgot-password-modal .modal-dialog {
    display: inline-block;
    text-align: left;
    vertical-align: middle; }
    #forgot-password-modal .modal-dialog .modal-body {
      padding: 15px 45px; }
    #forgot-password-modal .modal-dialog p {
      padding-bottom: 15px; }
    #forgot-password-modal .modal-dialog .modal-header {
      border-bottom: 0;
      padding-top: 5px;
      padding-bottom: 0px; }
      #forgot-password-modal .modal-dialog .modal-header button {
        font-size: 30px; }

@media (min-width: 768px) {
  #forgot-password-modal:before,
  #login-modal:before {
    content: "";
    display: flex;
    align-items: center;
    display: inline-block;
    height: 100%;
    vertical-align: middle;
    margin-right: -4px; } }

#login-modal {
  position: absolute;
  top: 80px; }
  #login-modal .form-heading-mob {
    display: none; }
  @media (max-width: 767px) {
    #login-modal .form-heading {
      display: none; }
    #login-modal .form-heading-mob {
      display: block;
      text-align: center;
      margin-top: 0px;
      margin-bottom: 20px;
      font-weight: 600;
      color: #818181; } }
  #login-modal form {
    width: 90%;
    margin: 0 auto;
    margin-bottom: 30px; }
    #login-modal form .form-group {
      margin-bottom: 25px; }
    #login-modal form input {
      border-radius: 0px; }
    #login-modal form .error-message {
      color: #ff0000;
      padding: 2px; }
    #login-modal form button {
      opacity: 1;
      background-color: #a9a9a9;
      border: 1px solid #a9a9a9;
      color: #fff;
      font-size: 14px;
      font-weight: 600; }
  #login-modal .modal-header {
    min-height: 0;
    padding: 10px;
    border-bottom: none; }
  #login-modal .form-heading {
    color: #2e3192;
    margin-top: 0px;
    font-size: 20px; }
    @media (max-width: 767px) {
      #login-modal .form-heading {
        color: #818181; } }
  #login-modal .forgot-password-link {
    width: 90%;
    margin: 0 auto;
    text-align: right;
    color: #2e3192; }
    #login-modal .forgot-password-link span {
      border-bottom: 1px solid #337ab7;
      cursor: pointer; }
    #login-modal .forgot-password-link span:hover {
      color: #2e3192; }

.MessagesEditor-editor {
  border: 1px solid #e3e3e3;
  min-height: 230px;
  padding: 0 10px;
  border-radius: 2px !important;
  border-top-right-radius: 0px !important;
  border-top-left-radius: 0px !important; }

.public-DraftStyleDefault-block {
  margin: 2px 0px !important; }

.rdw-messages-dropdown {
  min-width: 170px; }

.rdw-placeholder-dropdown {
  min-width: 140px; }

.rdw-dropdown-option:hover {
  background: #F1F1F1; }

.rdw-message-dropdown-wrapper {
  position: relative; }

.mdx-dropdown {
  position: absolute;
  width: 100%;
  min-width: 270px;
  top: 35px;
  background: #fff;
  z-index: 1001;
  left: 0;
  max-height: 210px;
  overflow: auto;
  border: 1px solid #efefef !important; }
  .mdx-dropdown li > a {
    color: #000 !important;
    border: none !important;
    padding: 7px 10px !important; }
  .mdx-dropdown .mdx-save-reply-option {
    color: #337ab7; }

.rdw-link-modal {
  left: auto !important;
  border: 1px solid #ccc !important; }

.Retailer-ContactForm .rdw-link-modal {
  left: 5px !important;
  border: 1px solid #ccc !important; }

.rdw-editor-toolbar {
  border: 1px solid #e3e3e3 !important;
  margin-bottom: 0px !important;
  border-bottom: 0px !important;
  border-bottom-right-radius: 0px !important;
  border-bottom-left-radius: 0px !important; }

.footer-attach__file-upload {
  position: relative;
  overflow: hidden;
  margin: 10px;
  top: 0px;
  right: 5px; }
  .footer-attach__file-upload #uploadFile {
    display: none; }
  .footer-attach__file-upload span {
    text-decoration: underline;
    cursor: pointer; }
  .footer-attach__file-upload input.upload {
    position: absolute;
    top: 0;
    right: 0;
    margin: 0;
    padding: 0;
    font-size: 20px;
    cursor: pointer;
    opacity: 0; }

.attachments {
  padding-left: 32px; }
  .attachments .remove-icon {
    color: red; }
  .attachments .remove-file {
    border: none;
    background-color: white; }

#welcome-modal .welcome-modal__form input {
  margin-right: 10px; }

#welcome-modal .modal-header {
  border-bottom: none; }
