.user-retailer-association {
  .retailer-table-loader {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;

    i {
      font-size: 32px;
      -webkit-animation: spin 2s linear infinite; /* Safari */
      animation: spin 2s linear infinite;
    }

    /* Safari */
    @-webkit-keyframes spin {
      0% {
        -webkit-transform: rotate(0deg);
      }
      100% {
        -webkit-transform: rotate(360deg);
      }
    }

    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }

  .retailer-list {
    h2 {
      padding: 10px;
      background: #ececec;
      text-align: center;
    }
    .ellipsis-con {
      cursor: pointer;
      button {
        font-size: 12px;
        border: 0;
        padding: 0;
        color: #337ab7;
      }
      button:hover {
        color: #23ccef;
      }
    }
    .back-btn-div {
      margin-top: 20px;
      display: flex;
      gap: 20px;
      a,
      label {
        display: flex;
        align-items: center;
        vertical-align: middle;
        cursor: pointer;
        padding: 5px;
        background: #ececec;
        border: 1px solid lightgray;
        margin-bottom: 15px;
        border-radius: 4px;
        color: black;
        font-weight: 600;
        width: 8%;

        i {
          font-size: 24px;
          width: 30px;
        }
      }
    }
  }

  .retailer-users-table {
    .ReactTable {
      .retailerText {
        display: inline-block;
        text-align: justify;
      }
      .table-cell-edit {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 30px;

        i {
          font-size: 20px;
          cursor: pointer;
        }
      }
    }
  }

  .ReactTable .rt-th,
  .ReactTable .rt-td {
    overflow: visible;
    white-space: normal;
  }
}

// modal css
.checkListForm {
  height: 70vh;
  overflow: auto;
  .checkListItems {
    column-count: 3;

    .retailerCheckList {
      // display: flex;
      input {
        padding: 0px 10px 5px;
        cursor: pointer;
      }

      label {
        padding: 0px 10px 5px;
        font-size: 14px;
        font-weight: normal;
        text-transform: capitalize;
      }
    }
  }
  // .popup-btn {
  //   button {
  //     margin: 10px;
  //     border-radius: 4px !important;
  //   }
  // }
}
//modal css
.RetailerPopUpModal {
  .modal-dialog {
    width: 1000px;
  }
  .popup-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    .btn {
      margin-right: 20px;
      margin-top: 20px;
    }
  }
}

.EditRetailerPopUpModal {
  .modal-dialog {
    width: 500px;
    .modal-content {
      .modal-header {
      }
      .modal-body {
        .retailer_edit {
          display: flex;
          flex-direction: column;
          gap: 10px;
          .retailer_input {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            label {
              width: 15%;
            }
            input {
              width: 83%;
            }
          }
          div.retailer_input:last-child() {
            justify-content: flex-end !important;
          }
        }
      }
    }
  }
}
