#privacy-policy {
	display: flex;
  justify-content: left;
  align-items: center;
  min-height: 90vh;
	max-width: 600px;
	.text__style {
		font-size: 14px;
	}
	.privacy-policy__block {
		border: 1px solid #ddd;
	  border-radius: 4px;
		padding: 20px;
		margin-top: 20px;
		.privacy-policy__heading {
			display: flex;
	    justify-content: center;
	    align-items: center;
			text-align: center;
	    .privacy-policy__heading_logo {
	    	width: 50%;
				img {
					max-width: 230px;
				}
	    }
	    .privacy-policy__heading_text {
	    	width: 50%;
	    	padding: 10px;
	    }
		}
		.privacy-policy__terms {
			text-align: left;
			height: 400px;
			overflow: auto;
		}
		.privacy-policy__form {
			margin-top: 20px;
			text-align: center;
			.privacy-policy__form__accept-btn {
				color: #fff;
				background: #000;
			}
		}
	}
}