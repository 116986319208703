.manage-users {
  .user-table-loader {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;

    i {
      font-size: 32px;
      -webkit-animation: spin 2s linear infinite; /* Safari */
      animation: spin 2s linear infinite;
    }

    /* Safari */
    @-webkit-keyframes spin {
      0% {
        -webkit-transform: rotate(0deg);
      }
      100% {
        -webkit-transform: rotate(360deg);
      }
    }

    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }
  .add-user-con {
    margin-bottom: 15px;
  }
  .back-btn-div {
    margin-top: 20px;
    a {
      display: flex;
      align-items: center;
      vertical-align: middle;
      cursor: pointer;
      padding: 5px;
      background: #ececec;
      border: 1px solid lightgray;
      margin-bottom: 15px;
      border-radius: 4px;
      color: black;
      font-weight: 600;
      width: 8%;

      i {
        font-size: 24px;
        width: 30px;
      }
    }
  }
}

