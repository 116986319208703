.default-btn {
  text-transform: uppercase;
  font-weight: bold !important;
  color: #000 !important;
}
#SettingsView {
  .user-setting {
    .img-icon {
      height: 24px;
      width: 26px;
    }
  }
}
.contact-tab__menu {
  .setting-form {
    display: flex;
    flex-direction: row;
    .contact-tab__menu--leftcol {
      flex: 0 230px;
      padding-right: 0 !important;
      h4 {
        text-transform: none !important;
      }
      ul > li {
        width: 100%;
      }
      .nav > li > a:hover,
      .nav > li > a:active {
        color: #555;
        border: 1px solid #ddd !important;
      }
      .nav-tabs > li > a {
        border-right: none !important;
        padding: 5px 15px;
      }
      .nav-tabs > li.active > a,
      .nav-tabs > li.active > a:focus,
      .nav-tabs > li.active > a:hover {
        color: #555;
        cursor: default;
        background-color: #fff;
        border: 1px solid #ddd !important;
        border-right: none !important;
      }
    }
    .contact-tab__menu--rightcol {
      flex: 3 0px;
      border: 1px solid #ddd;
      min-height: 58vh !important;
      h4 {
        text-transform: none !important;
      }
      .tab-content {
        border: none !important;
        margin: 0 auto;
      }
      .import-contacts {
        #tabs-for-contact {

          .import-contact__steps {
            padding-top: 17px;
            .tab-content {
              max-width: 1200px;
            }
            #tabs-for-contact-pane-choose-type {
              .row {
                display: flex;
                align-items: center;
                div {
                  text-align: left;
                }
              }
              padding-top: 15px;
            }
          }
        }
        .cancel-btn {
          border: none;
          font-weight: normal !important;
          text-transform: capitalize;
        }
        ul.nav.nav-tabs {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-wrap: wrap;
          padding: 10px;
        }
        .nav-tabs {
          border-bottom: 0px;
          li {
            flex-grow: 1;
            flex-basis: 0;
            max-width: 400px !important;
            a {
              text-align: center;
              padding: 2px 15px;
            }
          }
        }
        .nav-tabs > li.active > a,
        .nav-tabs > li.active > a:focus,
        .nav-tabs > li.active > a:hover {
          color: #555;
          cursor: default;
          background-color: #fff;
          border: 1px solid #ddd !important;
        }
        .nav-tabs > li > a:hover {
          border: 1px solid #ddd;
          color: #555;
        }
        .import-type__btnsblock {
          display: flex;
          align-items: center;
          .import-type__btns {
            .btn {
              padding: 6px 10px !important;
              font-size: 12px;
            }
            .upload-csv {
              margin-right: 15px;
            }
          }
        }
        .upload__btn {
          padding: 0;
        }
        .import-type__sampledownloads {
          display: flex;
          align-items: center;
          margin-top: 40px;
          .sample-downloads {
            padding: 0;
            a {
              cursor: pointer;
              color: #0000ee !important;
              margin-left: 10px;
              margin-right: 10px;
            }
          }
        }
        .review-tab {
          .tab-title {
            font-size: 14px;
            margin-bottom: 20px;
          }
          .review-con {
            margin: 0 auto;
            text-align: center;
            .contact-numbers {
              margin-bottom: 20px;
              color: #777777;
              font-size: 15px;
            }
            .handle-files {
              margin-bottom:15px;
              font-size: 12px;
              font-weight: bold;
            }
            .review-radios {
              margin: 0 auto;
              width: auto;
              text-align: left;
              display: table;
              div {
                margin-bottom: 10px;
                input {
                  margin-bottom: 5px;
                }
                span {
                  margin-left: 5px;
                  font-size: 12px;
                }
              }
            }
          }
          .import-file-btns {
            text-align: right;
            margin-top: 20px;
            .cancel-btn {
              text-transform: uppercase;
            }
            .import-btn {
              margin-right: 10px;
              padding: 5px 10px;
              width: 120px;
            }
          }
        }

        .match-data-tab {
          .match-data-tab__title {
            font-size: 16px;
          }
          .match-data-tab__info {
            font-size: 12px;
            margin-bottom: 15px;
          }
          .match-data-table {
            .fa-sort {
              color: #000;
              font-size: 15px;
            }

            .file-row {
              text-align: center;
            }

            .field-select {
              .field-select__box {
                margin: 0 auto;
              }
            }
            td {
              width: 200px;
              padding: 5px;
            }
            th {
              color: #000;
              background-color: #f5f5f5;
              padding: 5px;
              text-transform: none;
              text-align: center;
            }
            td,
            th {
              font-size: 12px;
            }
          }
          .match-data-btns {
            text-align: right;
            margin-top: 15px;
            .cancel-btn {
              text-transform: uppercase;
            }
            .next-btn {
              margin-right: 15px;
              width: 125px;
              padding: 5px;
            }
          }
        }
      }
      .export-contact {
        margin: 15px 0 0 10px;
        .export-contact-title {
          display: flex;
          align-items: center;
          margin-bottom: 20px;
          h6 {
            text-transform: capitalize;
            font-size: 12px;
          }
          span {
            margin-left: 5px;
            font-size: 11px;
          }
        }
        .export-contacts-btns {
          .btn {
            font-size: 12px;
            padding: 6px 10px !important;
          }
          margin-top: 10px;
          span {
            margin: 10px;
          }
        }
      }

      .import-export-history {
        margin-top:20px;
        .col-header {
          i {
            margin-right: 3px;
          }
          padding: 5px 0 5px 10px;
          color: #fff;
          text-align: left;
        }
        .downloaded-files {
          .col-header {
            background-color: #4c9fed;
            font-size: 13px;
          }
        }
        .uploaded-files {
          .col-header {
            background-color: #80bd4f;
          }
        }
        .leftCol,
        .rightCol {
          overflow: scroll;
          height: 400px;
          border: 1px solid #d3d3d3;
          .text-center {
            margin-top: 10px;
          }
        }
        ul {
          columns: 2;
          margin-top: 10px;
          padding: 5px 0 0 10px;
          li {
            font-size: 13px;
            list-style: none;
            list-style-position: inside;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            cursor: pointer;
          }
        }
      }
    }
  }
}

#success-modal {
  .modal-dialog {
    max-width: 450px;
    -webkit-transform: translate(0, -50%);
    -o-transform: translate(0, -50%);
    transform: translate(0, -50%);
    top: 50%;
    left: 0;
    margin: 0 auto;
    .modal-title {
      font-size: 16px;
    }
    .success-modal-con {
      text-align: center;
      font-size: 13px;
    }
    .success-modal__btns {
      text-align: center;
      margin-top: 10px;
      .continue-btn {
        border-color: #777;
        font-size: 12px;
      }
    }
  }
}
#upload-modal {
  .modal-dialog {
    max-width: 350px;
    -webkit-transform: translate(0, -50%);
    -o-transform: translate(0, -50%);
    transform: translate(0, -50%);
    top: 50%;
    left: 0;
    margin: 0 auto;
    .modal-content {
      // text-align: center;
      .btn {
        width: 110px;
        font-size: 13px;
      }
      padding: 30px;
      .file-upload, .upload-modal__btns {
        margin-left: 20px;
      }
      .upload-modal__btns {
        display: flex;
        justify-content: space-evenly;
        margin-top: 30px;

        .upload-btn {
          margin-right: 10px;
        }
        .cancel-btn {
          cursor: pointer;
          width: 100px;
          text-align: left;
          text-transform: uppercase;
          align-self: center;
          color: #000;
        }
      }
      .file-upload {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        .choose-file-btn {
          margin-right: 10px;
        }
        .file-tooltip {
          margin-left: 0 !important;
        }
        .file-name {
          width: 100px;
          color: #777;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          font-weight: 100;
          cursor: pointer;
        }
        .file-upload_btn {
          display: none;
          max-width: 200px;
        }
      }
    }
  }
}