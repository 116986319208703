// shared scss
$primary-color: #333;
$border-color: #ccc;
$color-green: #74bc87;
$color-black: #000000;
$color-light-black: #646464;
$color-gray: #777777;
$color-red: #ff0000;
$color-white: #ffffff;
$color-blue:  #337ab7;
$xs-screen: 767px;
$sm-screen: 991px;
$md-screen: 1199px;