#post-office {
  background: #fff;
  min-height: 100vh;
  padding: 0px;
  .admin-route {
    margin-top: 5px;
  }

  .alert {
    margin-top: 30px;
    text-align: center;
  }

  .loadPrevFile {
    background:#5bc0de;
    color:#fff;
    border-radius: 3px !important;
  }

  .backBtn {
    // margin-top: 15px;
    cursor: pointer;
    padding: 5px;
    display: flex;
    width: 100px;
    background: #ececec;
    border: 1px solid lightgray;
    margin-bottom: 15px;
    border-radius: 4px;

    i {
      font-size: 24px;
      cursor: point1er;
      width: 30px;
      color: #333;
    }

    span {
      cursor: pointer;
      margin-top: 2px;
      font-size: 16px;
      font-weight: bold;
      color: #333;
    }
  }

  .backBtn:hover {
    background: #b3acac;
    span,
    i {
      color: #fff;
    }
  }

  // post-office header
  .po-header {
    padding:0 10px 5px;
    box-shadow: 0px 10px 5px -10px #000000;
    .user-retailer-list {
      padding: 5px;
    }
    .sitenaylsis-admin {
      display: flex;
      vertical-align: middle;
      justify-content: center;
      align-items: center;

      a {
        text-decoration: underline;
        font-size: 12px;
      }
    }
    .retailer-list-link {
      margin-top: 25px;
      color: #9a9a9a;
      font-size: 12px;
      background: #fff;
      border-radius: 3px;
      opacity: 1;
      border-color: lightgrey;
      padding: 8px 8px;
      white-space: inherit;
    }
    .row {
      margin-right: 0px;
    }
    .po-logo {
      display: inline-block;
      padding-top: 20px;
      // margin-top: 12px;
      img {
        max-width: 180px;
        height: 95px;
      }
    }
    .po-header-rcol {
      //common css start
      padding: 0px;
      .row {
        display: -ms-flex;
        display: -webkit-flex;
        display: flex;
        justify-content: flex-end;
      }
      .user-retailer-list,
      .po-logout,
      .po-go-live,
      .po-settings,
      .po-requirement,
      .po-sorting,
      .po-notification {
        padding: 5px;
        font-size: 12px;
        p,
        span {
          font-size: 12px;
          margin: 0px;
        }
      }
      .po-notification-con,
      .retailer-con,
      .po-logout-con,
      .po-go-live-con,
      .po-settings-con,
      .po-requirement-con,
      .po-sorting-con {
        height: 100%;
        background: #d3d3d36b;
        background: rgba(211,211,211,0.42);
        padding: 3px 10px 8px 10px;
      }

      .po-sort-by-dropdown-wrapper,
      .po-filter-by-dropdown-wrapper {
        select {
          width: 100%;
          cursor: pointer;
          border: 2px solid lightgray;
          background: #fff;
        }
      }

      // common css end

      .po-logout-con {
        // display: flex;
        // align-items: center;
        // vertical-align: middle;
        // justify-content: center;
        a {
          // background: #fff;
          // color: #000;
          color: #9a9a9a;
          background-color: #fff;
          border-radius: 3px;
          opacity: 1;
          padding: 8px 8px;

          i {
            font-size: 14px;
            padding-right: 4px;
          }
        }
        a:hover {
          background: #b3acac;
          color: #fff;
        }
        .admin-text {
          text-align: center;
          padding-top: 18px;
          padding-bottom: 25px;
          font-size: 14px;
        }
        .po-log-icon {
          text-align: center;
          i {
            cursor: pointer;
            width: 100%;
            font-size: 14px;
            color: #d2d2d2;
            padding: 10px 0px;
          }
        }
      }

      .po-settings-con i:hover {
        &::before {
          color: #b3acac;
        }
      }
      .po-settings-con,
      .po-notification-con {
        text-align: center;
        padding-bottom: 11px;
        cursor: pointer;
        i {
          cursor: pointer;
          width: 100%;
          font-size: 45px;
          color: #d2d2d2;
          padding: 16px 0px;
        }
        p {
          padding: 7px 0px 9px 0px;
        }
      }
      .po-sorting-con {
        padding: 5px 8px;
      }
      .po-go-live-con {
        padding-top: 8px;
        .po-next-live {
          font-size: 14px;
        }
        p {
          margin-bottom: 7px;
        }
      }
      .po-requirement-con {
        padding-top: 10px;
        padding-bottom: 13px;
        .po-review,
        .po-caution,
        .po-unflagged {
          text-align: right;
          padding: 10px 0px 2px 0px;
          span {
            display: inline-block;
            text-align: right;
            padding: 0px 2px;
            margin-left: 5px;
            border-radius: 5px;
            width: 30px;
            text-align: center;
          }
        }

        .po-review {
          span {
            background: #bd072f;
            color: white;
          }
        }
        .po-caution {
          span {
            background: yellow;
          }
        }
        .po-unflagged {
          span {
            background: #c0c0c0;
          }
        }
      }
    }
  }

  // product list of post-office
  .po-products {
    margin: 15px 15px;
    @media (max-width: 767px) {
      margin: 2px;
    }
    .po-products-con {
      @media (max-width: 767px) {
        overflow-x: scroll;
        overflow-y: scroll;
      }
    }
    .pagination {
      li:hover > a {
        background: #c0c0c0;
      }
      li.active > a {
        background: #c0c0c0;
      }
    }

    .po-product-content {
      padding: 15px;
      background: #f6f6f6;
      margin-bottom: 15px;
      cursor: pointer;
      display: flex;
      justify-content: space-between;

      @media (max-width: 767px) {
        margin-bottom: 10px;
        padding: 5px 5px;
      }
      .arrow-icon {
        font-size: 24px;
      }
      .alert {
        margin-top: 50px;
      }

      .leftContent,
      .rightContent {
        display: flex;
        .file-text {
          padding: 0px 8px 2px 8px;

          strong {
            font-size: 15px;
          }
        }

        .item {
          padding: 3px 8px 0px 8px;
          margin-left: 15px;
          // background: #c0c0c0;
          @media (max-width: 767px) {
            padding: 2px;
            margin-left: 8px;

          }
          background: #8b818163;
          font-size: 12px;
          text-transform: capitalize;
          border-radius: 7px;
        }

        .require_review {
          background: #bd072f;
          color: white;
        }
        .caution {
          background: #ffff00;
        }
        .unflagged {
          background: #8b818163;
        }
        /*  .fileNameColor {

        } */
      }

      .rightContent {
        .item {
          margin-right: 30px;
          background: #4000ff41;
          color: white;
        }
        .rejected,
        .approved,
        .pending {
          margin-right: 30px;
          text-transform: uppercase;
          font-size: 12px;
          padding: 4px 8px 0px 8px;
          color: white;
          border-radius: 7px;
        }
        .rejected {
          background: #e11b28;
        }
        .approved {
          background: #009e0e;
        }
        .pending {
          background: #8b818163;
          color: #333;
        }
      }
    }

    @media (max-width: 767px) {
      .fileNameCon,
      .file-text,
      .fileType {
        display: none;
      }
      .itemFileName {
        margin-left: 0 !important;
        width:300px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

      }
      .rightContent {
        display: flex;
        align-items: center;
        vertical-align: middle;
      }
    }
  }

  //  file-comparison
  .po-comparison-view {
    margin: 25px 15px;
    .item {
      cursor: default;
    }

    .po-product-content {
      background: #f6f6f6;
      padding: 12px 12px 10px 3px;
      margin-bottom: 15px;
      border-bottom: 2px solid #fff;
      // cursor: pointer;
      display: flex;
      width: 100%;
      justify-content: space-between;

      .alert {
        margin-top: 20px;
      }

      .leftContent {
        display: flex;
        .file-text {
          padding: 0px 8px 2px 8px;
          strong {
            font-size: 15px;
          }
        }

        .item {
          padding: 3px 8px 0px 8px;
          margin-left: 15px;
          // background: #c0c0c0;
          background: #8b818163;
          font-size: 12px;
          text-transform: capitalize;
          border-radius: 7px;
        }
        .require_review {
          background: #bd072f;
          color: white;
        }
        .caution {
          background: #ffff00;
        }
        .unflagged {
          background: #8b818163;
        }
      }

      .rightContent {
        display: flex;
        .item {
          padding: 3px 8px 0px 8px;
          // background: #c0c0c0;
          background: #4000ff41;
          font-size: 12px;
          text-transform: capitalize;
          border-radius: 7px;
          margin-right: 30px;
          color: #fff;
        }
        .right-item {
          padding: 2px 8px;
          margin-left: 15px;
          border-radius: 2px;
          font-size: 12px;

          /*   .fa {
            font-size: 40px !important;
          } */
        }
        .rejected,
        .approved,
        .pending {
          margin-right: 30px;
          text-transform: uppercase;
          font-size: 12px;
          padding: 3px 8px 0px 8px;
          color: white;
          border-radius: 7px;
        }
        .rejected {
          background: #e11b28;
        }
        .approved {
          background: #009e0e;
        }
        .pending {
          background: #8b818163;
          color: #333;
        }
        .approved-btn {
          background: transparent;
          border: 2px solid #a9f01ebf;
          color: #000;
          font-weight: 600;
          i {
            color: #a9f01ebf;
          }
        }

        .need-attention-btn {
          background: transparent;
          border: 2px solid #bd072f;
          color: #000;
          font-weight: 600;
          i {
            color: #bd072f;
          }
        }
        .approved-btn:hover {
          background: #b1e84480;
        }
        .need-attention-btn:hover {
          background: #bd072f40;
        }

        .thumb-icon {
          font-size: 18px;
          color: #ffcd94;
        }

        .arrow-icon {
          font-size: 20px;
          padding-top: 2px;
        }
      }
    }

    .same-period-files {
      margin: 45px 0px;
      .period-file {
        cursor: pointer;
        .item,
        .arrow-icon {
          cursor: pointer;
        }
      }

      .period-file:hover {
        background: #b3acac;
        border-radius: 4px;
        color: #fff;
      }
      legend {
        padding-bottom: 5px;
        font-size: 20px;
        font-weight: 600;
      }
    }

    // table view
    .po-file-view {
      padding: 0px 8px;
      .alert {
        margin: 25px 0;
      }

      .badge {
        margin-left: 10px;
        background: #8b818163;
        color: rgb(51, 51, 51);
        font-weight: normal;
        font-size: 12px;
        border-radius: 5px;
      }

      .po-current-file-view,
      .po-previous-file-view {
        .po-current-products-table-wrap,
        .po-previous-products-table-wrap {
          overflow: scroll;
          min-height: 250px;
          max-height: 100vh;
          border: 1px solid lightgray;
        }

        .po-current-products-col-header {
          label {
            font-size: 15px;
          }
        }
        .po-current-products-table-wrap,
        .po-previous-products-table-wrap {
          td,
          th {
            padding: 10px;
            position: relative;
            outline: 0;
          }
          th {
            font-weight: bold;
            color: #fff;
            background-color: #3935358a;
          }
          tr > td {
            padding: 2px;
            font-size: 12px;
            position: relative;
          }

          .tableIndex {
            padding: 0px 4px;
            text-align: center;
          }
        }
      }
    }

    .homePageType,
    .brandPageType,
    .productType,
    .reviews,
    .brandDis {
      min-width: 400px;
    }
    @media (max-width: 767px) {
      .fileNameCon,
      .fileType {
        display: none;
      }
    }
  }

  // post-office setting
  .po-administration {
    margin: 25px 15px;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;

    .onSetting-nav-btn {
      display: flex;
      a.link-to-nav {
        cursor: pointer;
        padding: 5px;
        font-size: 14px;
        margin: 0 10px 15px;
        border-radius: 4px;
        text-decoration: underline;
      }
    }

    p {
      text-decoration: underline;
      font-weight: 700;
      margin-bottom: 15px;
    }

    .po-admin-save-btn {
      margin-top: 15px;
      background: #00618f;
      color: #fff;
      border-radius: 5px !important;
      border: 1px solid lightgray;
    }

    .threshHoldCon {
      background-color: #eaecee;
      padding: 15px 15px 30px 15px;
      margin: 0px;

      .po-admin-form {
        flex: 1;
        padding: 0px 10px;
        background-color: #eaecee;
        .po-admin-email-btn {
          margin-top: 15px;
          margin-left: 5px;
          background: #00618f;
          color: #fff;
          border-radius: 5px !important;
        }

        .inputControls {
          margin-bottom: 5px;
          label {
            font-weight: normal;
            padding: 0px;
          }
          .emailSelection {
            padding: 0px;
            margin-bottom: 10px;
          }
          .password {
            padding: 0px;
          }
        }
      }

      .po-thresholds {
        border-left: 1px solid #a6a6a6cf;
        padding-left: 25px;
        .image-data-checked {
          margin-bottom: 15px;
          .right-item {
            width: 22px;
          }
        }

        .grid-content {
          .grid-text {
            padding-bottom: 4px;
            display: flex;
            justify-content: space-between;

            .left-item-heading {
              font-weight: normal;
              color: #000;
              font-size: 14px;
            }
            .left-item {
              font-size: 12px !important;
            }
            .right-item {
              .inputValue {
                width: 32px;
                height: 17px;
                font-size: 12px;
                padding: 0px 0px;
                text-align: center;
                border: 2px solid #d3d3d3eb;
                border-radius: 5px;
              }
            }
          }
        }
      }
    }
  }
  .po-pagination {
    text-align: center;
    margin-bottom: 30px;
    .pagination {
      margin-bottom: 5px;
    }
    .pagination > li > a, .pagination > li > span, .pagination > li:first-child > a, .pagination > li:first-child > span, .pagination > li:last-child > a, .pagination > li:last-child > span {
      border-radius: 0;
      outline: none;
    }
    .pagination > li:first-child > a,
    .pagination > li:first-child > span {
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
    }
    .pagination > li:last-child > a,
    .pagination > li:last-child > span {
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
    }
    p {
      font-size: 12px;
      text-align: center;
      margin: 0;
    }
    .pagination > li.active > a,
    .pagination > li:hover > a {
      color: #000;
      background-color:#8b818163;
    }
  }
}