#admin-header {
  .navbar {
    margin-bottom: 5px;
  }
  .navbar-brand {
    margin: 0 15px;
  }
  .navbar-nav {
    margin: 10px 0;
    li {
      a {
        i {
          margin-right: 5px;
        }
      }
    }
  }
}
.admin-header {
  background-color: #fff;
  padding: 10px 0 6px;
  border-bottom: 1px solid lightgray;
  .sitenalysis_logo {
    max-width: 180px;
  }
  .logout-button {
    border-color: gray !important;
  }
  .admin-logout {
    text-align: right;
    .logout-button {
      padding: 6px 8px;
    }
  }
}
.admin-con {
  .admin-con-options {
    text-align: center;
    border: 1px solid lightgray;
    border-bottom: 0px;
    // padding: 15px;
    margin-top: 20px;
    ul {
      padding-left: 0px;
      .list-group-item {
        border: 1px solid lightgray;
        border-radius: 0px;
        border-left-width: 0px;
        border-right-width: 0px;
      }
    }
  }
}
