.login-page {
  padding-top: 4vh;
  position: relative;
  .alert-info,
  .alert-danger {
    text-align: center;
    border-radius: 2px !important;
    font-size: 12px;
    button.close {
      position: relative !important;
      right: -15px;
      background-color: transparent !important;
    }
  }
  .login-form {
    background: #537e9a;
    width: 35%;
    max-width: 470px;
    position: absolute;
    top: 100px;
    left: 50%;
    transform: translateX(-50%);
    @media (max-width: 991px) {
      width: 100%;
    }
    .brand-logo {
      background: #fff;
      max-width: 100%;
      padding-top: 20px;
      padding-bottom: 10px;

      h5 {
        margin-top: 0px;
      }

      p {
        font-size: 14px;
      }

      .logo {
        max-width: 250px;
      }
    }
    .card {
      text-transform: uppercase;
      padding-top: 25px;
      border-radius: 15px;
      box-shadow: 0px 2px 7px 3px #cfc8c8;
      margin-bottom: 0;
      .content {
        width: 92%;
        margin: auto;
      }
      .header {
        padding-top: 0px;
        padding-bottom: 5px;
        .title {
          a {
            color: black;
            font-size: 34px;
            font-weight: 500;
          }
        }
      }
    }
    .login-loader {
      display: none;
    }
    input {
      border-radius: 0 !important;
    }
    .btn-wd {
      border-radius: 0 !important;
      width: 92% !important;
      text-transform: uppercase;
      opacity: 1;
      font-size: 14px;
      font-weight: 600;
    }
  }
  .message-alert {
    width: 320px;
    max-width: 470px;
    cursor: pointer;
    position: absolute;
    top: 30px;
    left: 50%;
    transform: translateX(-50%);
    @media (max-width: 991px) {
      width: 320px;
    }
    @media (max-width: 767px) {
      width: 90%;
    }
    .alert-info {
      background-color: #d9edf7;
      color: #31708f;
      border: 1px solid #a7c2cf;
      button {
        color: #31708f;
        opacity: 1;
      }
    }
    .alert-danger {
      background-color: #ffe6e6;
      color: #ff5151;
      border: 1px solid #eb5d5d;
      button.close {
        opacity: 1;
        color: #ff5151;
      }
    }
  }
}
