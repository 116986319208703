.permissions-menu {
  h2 {
    background: #d3d3d36b;
    padding: 10px;
    margin-bottom: 40px;
  }
  #permissions-tab {
    display: flex;
    width: 60%;
    margin-top: 20px;
    .back-btn-div {
      width: 15%;
      a {
        display: flex;
        align-items: center;
        vertical-align: middle;
        cursor: pointer;
        padding: 5px;
        background: #ececec;
        border: 1px solid lightgray;
        margin-bottom: 26px;
        border-radius: 4px;
        color: black;
        font-weight: 600;
        width: 80%;
        i {
          font-size: 24px;
          width: 26px;
        }
      }
    }
    .permissions-tab__form {
      width: 85%;
      form {
        display: flex;
      }
      .permissions-tab__filters {
        width: 89%;
        margin-left: 50px;
        #retailer-select,
        #brand-select {
          display: flex;
          align-items: center;
          label {
            font-weight: normal;
            width: 25%;
            text-align: right;
            padding-right: 10px;
          }
          .react-select {
            width: 44%;
          }
          .search-style {
            margin-left: 10px;
          }
          #search-retailers,
          #search-brands {
            width: 100%;
            border-color: #ccc;
            border-radius: 4px !important;
          }
        }
      }
      .reset-btn-div {
        width: 11%;
        text-align: right;
      }
    }
  }
  .searchedItems {
    .tableInfo {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin: 10px 0px 10px 0px;
      .tableCount {
        margin-right: 30px;
        color: #777;
        font-size: 16px;
      }
    }
    h4 {
      text-align: center;
      span {
        color: #777777;
      }
    }
    .export-btn {
      /* border-color: #337ab7;
      color: #337ab7; */
      color: #000000;
      border-color: #444;
      text-transform: uppercase;
      line-height: 20px;
      font-size: 14px;
      font-weight: 700;
    }
    .btn i {
      margin: 0px;
    }
    .export-btn:hover {
      color: #000;
      border-color: #000;
    }
  }
  .rt-th input {
    text-align: center;
  }
  .rt-resizable-header-content {
    span {
      margin-left: 10px;
      font-size: 20px;
    }
  }
  .rt-td {
    text-align: center;
  }
}

