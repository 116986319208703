@import "assets/sass/_variables.scss";
#root {
  .navbar {
    .container-fluid {
      display: flex;
      .navbar-header {
        flex: 2;
        display: flex;
        align-items: baseline;
        .navbar-select-container {
          min-width: 200px;
          @media (max-width: 767px) {
            min-width: 0;
          }
        }
      }
      .navbar-collapse {
        flex: 1;
        text-align: right;
      }
    }
    .product-back-btn {
      margin-left: 10px;
      padding: 5px 18px 5px 10px;
      font-size: 14px;
      height: 35px;
      border: 1px solid lightgray;
      border-radius: 3px !important;

      i {
        width: 18px;
        margin: 0px;
      }
    }
  }
  .main-panel {
    .navbar {
      background: $color-white;
      .container-fluid {
        padding: 0 15px;
      }

      li {
        a {
          margin: 0;
        }
        .btn {
          margin: 0;
          span.btn-label {
            padding-right: 5px;
          }
        }
      }
    }
  }
}
