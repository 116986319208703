@import "assets/sass/_variables.scss";
$purple: #9368e9;
@mixin active {
  color: #fff;
  background-color: $color-blue;
}
.contentWrap {
  min-height: calc(100vh - 139px);
  .module-icon_con {
    padding: 6px 0px;
    .module-icon {
      margin-left: 5px;
      height: 25px;
      width: 35px;
    }
  }
}
#BrandSKU + div {
  height: 0;
}
.navbar-style .navbar-sm-style {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.navbar-style .navbar-xs-style {
  display: none;
}
body {
  font-family: "Roboto", sans-serif;
  font-weight: 400;

  &.sidebar-mini {
    #root {
      .sidebar {
        overflow: hidden;
      }
    }
  }
  .fab-icon {
    display: none;
    background: #a9a9a9;
    color: #fff;
  }
  a {
    color: $color-blue;
    word-break: break-word;
  }
  .popover {
    border: 1px solid #ccc;
    border: 1px solid rgba(0, 0, 0, 0.2);
    .popover-content {
      max-height: 400px;
      overflow: auto;
    }
    &.left {
      .arrow:after {
        border-left-color: #ccc;
      }
    }
    &.top {
      .arrow:after {
        border-top-color: #ccc;
      }
    }
  }
  .pagination {
    > li.active,
    > li:hover {
      > a {
        @include active;
        &:focus,
        &:hover,
        &:visited {
          @include active;
        }
      }
    }
  }
  .summary-view-v2 {
    .filter-by-select-container {
      width: 100%;
    }
  }
  .filter-by-select-container,
  .filter-by-rep-name {
    // width: 50%;
    display: flex;
    vertical-align: middle;
    align-items: center;
    margin-bottom: 8px;
    label {
      text-align: right;
      font-weight: normal;
      font-size: 12px;
    }
    .select-col {
      margin-left: 10px;
      width: 60%;

      .filter-ie {
        background: none;
      }
      .filter-default {
        background: url(data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0Ljk1IDEwIj48ZGVmcz48c3R5bGU+LmNscy0ye2ZpbGw6IzQ0NH08L3N0eWxlPjwvZGVmcz48cGF0aCBmaWxsPSIjZmZmIiBkPSJNMCAwaDQuOTV2MTBIMHoiLz48cGF0aCBjbGFzcz0iY2xzLTIiIGQ9Ik0xLjQxIDQuNjdsMS4wNy0xLjQ5IDEuMDYgMS40OUgxLjQxek0zLjU0IDUuMzNMMi40OCA2LjgyIDEuNDEgNS4zM2gyLjEzeiIvPjwvc3ZnPg==)
          no-repeat;
      }
      #filter-type-select {
        width: 100%;
        font-size: 12px;
        height: 35px;
        padding: 0 0 0 10px;
        border-radius: 0;
        text-indent: 0.01px;
        background-position: 97% 50%;
        -moz-appearance: none;
        -webkit-appearance: none;
        -webkit-border-radius: 0;
        outline: none;
      }
    }
  }
  .retailer-period-select-container {
    display: flex;
    .title-col {
      line-height: 30px;
    }
    .select-col {
      width: 21%;
      margin-left: 12px;
    }
  }

  .redirect-btn {
    border: none;
    color: $color-blue;
    cursor: pointer;
    background: transparent;
  }
  .drill-props {
    display: flex;
    margin-bottom: 5px;
    .brand-sku-info {
      span {
        cursor: pointer;
        color: blue;
        text-decoration: underline;
        margin-left: 8px;
      }
    }
    label {
      flex: 1;
      font-weight: 100;
    }
    div {
      flex: 2;
    }
    button {
      margin-top: 10px;
    }
    .flagBrand {
      display: inline-block;
    }
  }
  .retailer-intro-tab,
  .contact-info-tab,
  .email-history-tab,
  .omni-alert-info {
    padding: 0 20px;
  }
  .contact-info-tab {
    margin: 20px 0 10px;
    form {
      margin-top: 30px;
    }

    .contact-form-text {
      font-size: 14px;
      margin-bottom: 5px;
    }
  }

  .contact-col {
    margin-top: 10px;
  }
}

#root {
  .fa-fw {
    width: 1.28571429em !important;
  }
  .Stats-Loader {
    text-align: center;
    padding-top: 10%;
  }
  .Products-Loader {
    height: 600px;
    td {
      text-align: center;
    }
  }
  #ViewByProductView {
    .product-image {
      .Image-Loader {
        img {
          max-width: 175px;
        }
      }
    }
  }
  .sidebar-wrapper {
    .summary-view a {
      i {
        font-size: 20px;
      }
    }
    ul.nav {
      li.contact-us {
        display: none;
      }
    }
  }
  #minimizeSidebar {
    background: rgba(112, 112, 112, 0);
    border: none;
  }

  .text-purple {
    color: $purple;
  }
  .navbar {
    #minimizeSidebar {
      position: fixed;
      left: 200px;
      z-index: 9;
      background: transparent;
      border: 0 !important;
    }

    .header-section {
      @media (max-width: 1227px) {
        width: 80.33333333%;
      }

      @media (max-width: 1169px) {
        .omniconnect-search-form {
          max-width: 200px;
        }

        .view-option {
          padding: 15px 0;
          border-bottom: 0;
        }
      }

      @media (max-width: 1119px) {
        .omniconnect-search-form {
          max-width: 50%;
          float: left;
        }
      }
      @media (max-width: 1086px) {
        width: 76%;
        .view-option {
          .title-col {
            font-size: 14px;
          }
        }
      }
    }

    .Settings,
    .Contact {
      .view-option {
        display: none;
      }
    }

    .navbar-brand {
      font-size: 18px;
    }
  }
  .regular-table-parent {
    .header {
      h4 {
        color: $color-black;
        font-family: "Open Sans", sans-serif;
        font-weight: 700;
      }
    }

    .content {
      .table {
        thead {
          tr {
            th:first-child {
              .thRow-Inner {
                float: left;
              }
            }
            th {
              &.heading-0 {
                text-align: left;
              }

              &.product-list-heading-0,
              &.product-list-heading-1 {
                text-align: left;
              }
              color: $color-black;
              font-family: "Open Sans", sans-serif;
              font-weight: 700;
              text-align: center;
            }
          }
        }

        tbody {
          tr {
            td {
              &.product-name,
              &.retailer-product-name {
                text-align: left;
              }
              text-align: center;
            }
          }
        }
      }
    }
  }

  .viewing-container {
    .title-col {
      .control-label {
        position: relative;
        top: 10px;
      }
    }
  }

  #retailerView {
    padding: 20px;
    > .container-fluid {
      padding-right: 5px !important;
      padding-left: 5px !important;
    }
    .selected-retailer-name {
      display: flex;
      align-items: center;
      margin: 0 0 30px;
      padding-bottom: 10px;
      font-size: 15px;
      font-weight: 600;
      text-transform: uppercase;
      border-bottom: 1px solid #ccc;

      img {
        height: 20px !important;
        width: 30px;
        margin-left: 10px;
      }
    }
    .RetailerProductsTable {
      thead {
        th {
          text-align: center;
        }
      }
      .Image-Loader {
        text-align: center;
        img {
          max-width: 150px;
        }
      }
    }

    p {
      font-family: "Roboto", sans-serif;
      font-weight: 400;
    }

    .Image-Caption {
      font-size: 14px;
    }

    .header-col {
      h5 {
        margin: 0;
      }
    }

    .content-full-width {
      padding-bottom: 0;
    }
    .next-scanned-label {
      font-size: 12px;
      margin: 10px 0;
      p {
        font-size: 12px;
        margin: 2px;
      }
    }
    .retailer-intro,
    .retailer-tab {
      margin-top: 35px;
    }

    .retailer-intro {
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      padding-bottom: 30px;

      .flag-marked,
      .flag-unmarked {
        display: inline-block;
        padding-left: 5px;
      }
      .Image-Background {
        height: 300px;
        margin: 5px;
      }
    }

    .retailer-intro,
    .product-screens-container {
      .loading {
        margin: 0 auto;
        width: 80px;
      }

      .Thumbnail-Image {
        span {
          max-width: 300px;
          margin: 0 auto;
          text-align: center;
          i {
            margin: 0 auto;
            text-align: center;
          }
        }
      }
    }

    .retailer-tab,
    .retailer-info {
      .nav-tabs {
        li {
          margin-right: 0px !important;
          a {
            margin-right: 0px !important;
          }
        }
      }

      .card {
        box-shadow: none;
        background: transparent;
        margin: 0;
      }
      .OmniSwitch-Item {
        cursor: pointer;
        i {
          font-size: 24px;
        }
      }
      .product_add {
        cursor: pointer;
        i {
          margin-top: 15px;
          font-size: 20px;
        }
      }
      .content {
        #tabs-with-dropdown {
          .col-sm-12 {
            padding: 0 !important;
          }
        }
        .tab-content {
          border-top: 0;
          border-right: 1px solid $border-color;
          border-bottom: 1px solid $border-color;
          border-left: 1px solid $border-color;
          background: $color-white;

          .contact-retailer-btn-container {
            padding: 20px 15px 20px 0;
            .contact-retailer-btn {
              color: black;
              font-weight: 600;
              border: 1px solid lightgray;
              text-transform: uppercase;
            }
          }
        }

        .table-responsive {
          border: 1px solid $border-color;

          @media (max-width: 1140px) {
            width: 100%;
            margin-bottom: 15px;
            overflow-x: scroll;
            overflow-y: hidden;
            -ms-overflow-style: -ms-autohiding-scrollbar;
            -webkit-overflow-scrolling: touch;
          }

          .table-bigboy {
            thead {
              tr {
                th {
                  &.product-table-heading-0,
                  &.product-table-heading-1 {
                    text-align: center;
                  }
                  &.product-table-heading-1 {
                    min-width: 230px;
                  }
                  &.product-table-heading-5 {
                    max-width: 140px;
                  }
                  color: $color-black;
                  font-family: "Open Sans", sans-serif;
                  font-weight: 700;
                }
              }
            }
            tbody {
              tr {
                &.omni-alert-info {
                  td {
                    margin: 20px 0;
                  }
                }
                td {
                  font-family: "Roboto", sans-serif;
                  word-break: break-word;
                  font-weight: 400;
                  word-break: break-word;
                }
                &.no-flagged-item {
                  td {
                    font-size: 14px;
                  }
                }
              }
            }
          }
        }
      }
      .nav-tabs {
        // display: flex;
        // justify-content: space-between;
        li {
          padding-left: 0;
          margin-bottom: -5px;
          margin-right: 20px;

          @media (max-width: 1400px) {
            margin-right: 6px;
          }

          a {
            border: 1px solid #ddd;
            border-bottom-color: transparent;
            padding: 5px 10px;
            border-radius: 0;
            // padding-bottom: 21px;

            h4 {
              font-family: "Roboto", sans-serif;
              font-size: 13px;
              margin: 0;
              line-height: 20px;
              text-transform: uppercase;
              font-weight: 700;
              color: $color-gray;

              @media (max-width: 1400px) {
                font-size: 12px;
              }
              span {
                text-transform: initial;
              }
            }

            .last-scan-date {
              color: $color-gray;
              font-size: 10px;
              margin-bottom: 0px;
              text-align: center;
            }
            .active-tab {
              padding-bottom: 10px;
            }
          }
          &.active {
            a {
              padding-bottom: 5px;
              p {
                color: $color-black;
              }
              h4 {
                color: $color-black;
                line-height: 20px;
              }
              .last-scan-date {
                color: $color-gray;
                font-size: 10px;
                margin: 0px;
                text-align: center;
              }

              .active-tab {
                padding-bottom: 16px !important;
              }
            }
          }
        }
      }
    }

    .drill-down-parent {
      margin-top: 25px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      padding: 0 15px;

      .product-drilldown {
        h4 {
          margin-bottom: 25px !important;
          color: $color-black;
          text-transform: uppercase;
          font-family: "Open Sans", sans-serif;
        }

        .drill-props {
          margin-bottom: 5px;

          .property-name {
            text-transform: capitalize;
          }
        }

        .drill-props:last-of-type {
          margin-top: 0;
        }
        .drill-contact-col {
          margin-top: 40px;
        }
      }
    }
  }

  #retailerView,
  #retailerProductView,
  #post-office,
  #ViewByProductView {
    .Pagination-Container {
      padding: 20px 0;
      text-align: right;
      display: flex;
      align-items: center;
      /* justify-content: flex-start; */
      column-gap: 10px;
      justify-content: flex-end;

      .pagination {
        margin: 0;
        width: 68%;
        li {
          display: inline-block;
          margin-bottom: 1px;

          a {
            border-radius: 0px;
          }
        }
      }
    }
    .pTable-Label_Tr {
      &:hover {
        background-color: #fff;
      }
      background-color: #fff;
    }
    &.summary-view {
      i {
        font-size: 22px;
      }
      .regular-table-parent {
        .retailer-name {
          a {
            color: #000;
          }
        }
        a {
          color: blue;
        }
        .header {
          h4 {
            font-weight: 700;
            border-bottom: 1px solid $color-black;
          }
        }
        .content {
          .table {
            thead {
              tr {
                th.heading-1 {
                  text-align: center;
                }
              }
            }
          }
        }
      }
    }
    @media (min-width: $xs-screen) {
      .regular-table-parent {
        margin-top: 25px;
      }
    }

    @media (max-width: $xs-screen) {
      .regular-table-parent {
        h5 {
          font-size: 16px;
        }
      }
    }

    .product-screens-container {
      .product-details {
        background-color: $color-white;
        min-height: 220px;
        padding: 20px;
        word-wrap: break-word;
        label {
          margin-right: 5px;
        }
      }
    }

    .footer {
      .legend {
        div {
          display: inline-flex;
        }
        .text-info,
        .text-danger,
        .text-default {
          position: relative;
          top: 8px;
          margin-left: 15px;
        }
        .text-warning {
          position: relative;
          top: 8px;
        }
      }
    }

    .drill-down-parent {
      margin: 30px 0;
    }
  }
  #ViewByProductView {
    .Pagination-Container {
      padding: 20px 15px;
    }
  }

  .product-flag {
    width: 60%;
    border-color: lightgray;
    border-radius: 3px !important;

    i {
      margin-left: 5px;
    }
  }

  #retailerProductView-v2 {
    .product-drilldown {
      .product-flag {
        // display: inline-block;
        // position: absolute;
        right: 30px;
        bottom: 40px;
        i {
          margin-left: 5px;
        }
      }
    }
  }
  .social-feed {
    .graph-content {
      .header {
        max-width: 320px;
        width: 100%;

        h4 {
          border-bottom: 1px solid $border-color;
        }
      }
    }

    .content {
      .table {
        thead {
          tr {
            th {
              color: $color-black;
              font-family: "Open Sans", sans-serif;
              font-weight: 700;
            }
          }
        }
      }
    }
  }
}

.summary-view-v2 {
  // display: flex;
  // flex-flow: row;
  // overflow: hidden;
  .Image-Background {
    cursor: pointer;
    background-color: #fff;
    .Image-Spinner {
      text-align: center;
    }
  }
  .retailer-info {
    padding: 10px 15px 5px 15px;
    color: #ffffff;
    img {
      min-height: 0px !important;
      margin: 5px 0 0;
      height: 25px !important;
      width: 35px;
    }
    .view-retailer-stats-btn {
      border: 2px solid #fff;
      color: #fff;
      font-weight: bold;
      border-radius: 10px !important;
    }
    p {
      margin-top: 0px;
    }
  }
  .three-item-grid {
    .Image-Background {
      height: 280px;
    }
    .retailer-info {
      min-height: 195px;
      .retailer-info-right-column {
        margin-top: 10px;
      }
      .module-icon_con {
        margin-top: 7px;
        margin-bottom: 15px;
      }
    }
  }
  .two-item-grid {
    .Image-Background {
      height: 500px;
    }
    .retailer-info {
      min-height: 143px;
      display: flex;
      @media only screen and (max-width: $md-screen) and (min-width: $xs-screen) {
        display: block;
      }

      align-items: center;
      .retailer-info-left-column {
        width: 60%;
        @media only screen and (max-width: $md-screen) and (min-width: $xs-screen) {
          width: 100%;
        }
      }
      .retailer-info-right-column {
        width: 40%;
        text-align: right;
        @media only screen and (max-width: $md-screen) and (min-width: $xs-screen) {
          margin-top: 6px;
        }
      }
      .module-icon_con {
        margin-top: 6px;
        margin-bottom: 6px;
      }
    }
  }
  .one-item-grid {
    .Image-Background {
      height: 100vh;
      cursor: pointer;
      .Image-Spinner {
        text-align: center;
      }
    }
    .retailer-info {
      // display: flex;
      align-items: center;
      .retailer-info-left-column {
        width: 100%;
      }
      .retailer-info-right-column {
        width: 10%;
        text-align: right;
        margin-top: 6px;
      }
    }
  }

  .summary-view-v2-inner {
    margin: 20px;
    padding: 20px;
    background: #fff;
    h4 {
      margin-top: 0px;
      margin-bottom: 10px;
      font-weight: 600;
    }
    .filter-by-select-container {
      label {
        width: 35%;
      }
      .select-col {
        width: 65%;
      }
    }
    p {
      font-size: 14px;
      margin: 0px;
    }

    .heading-con {
      font-size: 14px;
      margin: 0px;
      margin-bottom: 35px;
      font-weight: 600;
    }

    .image-info {
      padding: 0 15px;
      .image-info-inner {
        padding: 5px;
        background-color: #ccc;
      }
      margin-bottom: 60px;
      .Image-Loader {
        border-bottom: 0px;
      }
      img {
        position: relative;
        max-width: 100%;
        min-height: 150px;
        @media (max-width: $xs-screen) {
          max-height: 330px;
        }
      }

      .logo-name {
        margin-left: 0%;
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        a {
          color: #ffffff;
        }
      }

      .retailer-state-link {
        margin-top: 10px !important;
        a {
          text-decoration: underline;
        }
      }

      /*  span {
        position: absolute;
        margin-top: -12px;
      } */
    }
  }

  .page-layout-changer {
    display: flex;
    align-items: center;
    @media only screen and (max-width: $xs-screen) and (min-width: 320px) {
      display: none;
    }
    .select-page-label {
      text-align: right;
      width: 35%;
      margin-bottom: 5px;
      font-size: 12px;
    }
    .page-layout-changer-row-1 {
      padding: 10px;
      border: 1px solid #555;
      text-align: center;
      font-size: 15px;
    }
    .page-layout-changer-row-2 {
      margin-left: 10px;
      border-top-width: 0;
      height: 35px;
    }
    .flexbox {
      display: flex;
      width: 65%;
      border-top: 1px solid #a6a6a6;
      border-bottom: 1px solid #a6a6a6;
      .flexbox-child {
        width: 33.333%;
        border-right: 1px solid #a6a6a6;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        &:first-child {
          border-left: 1px solid #a6a6a6;
        }
        &.active {
          .layout-btn {
            content: url("/consumer/module-icons/Tile-icon_active.png");
          }
          .layout-ie {
            content: url("/consumer/module-icons/newTestActive.png");
          }
          .layout-ie:after {
            content: url("/consumer/module-icons/newTestActive.png");
          }
        }
        .layout-btn {
          width: 15px;
          height: 14px;
          display: inline-block;
          margin-right: 5px;
          content: url("/consumer/module-icons/Tile-icon_deactive.png");
        }
        .layout-ie {
          width: 15px;
          height: 14px;
          display: inline-block;
          margin-right: 5px;
          content: url("/consumer/module-icons/newTestDeactive.png");
        }
        .layout-ie:after {
          content: url("/consumer/module-icons/newTestDeactive.png");
        }
      }
    }
  }
}

.modal-content {
  border-radius: 0 !important;
  .modal-body {
    .header {
      h4 {
        color: $color-black;
        font-family: "Open Sans", sans-serif;
        margin-top: 0;
        padding-left: 14px;
      }
    }
    .content {
      .table {
        thead {
          tr {
            th {
              color: $color-black;
              font-family: "Open Sans", sans-serif;
              font-weight: 700;
            }
          }
        }
      }
    }
    .save-btn {
      width: 100%;
    }

    #brandForm {
      .btn {
        width: 56.3333%;
        @media (max-width: 769px) {
          width: 100%;
        }
      }
    }
  }
}
.omni-spinner {
  min-height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  .fa {
    width: 1.28571429em;
  }
}

.OminiSwitch-Item {
  font-size: 24px;
}

body {
  .modal-lg {
    @media (min-width: $sm-screen) {
      width: 950px;
    }
    .modal-backdrop {
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 1040;
      background-color: #000;
    }
  }
}

// Global style for all containers
.page {
  padding: 30px 15px;
  h3 {
    margin: 0px 10px 22px 10px;
  }
}

.porduct-page {
  padding: 15px 15px;

  .product-name {
    margin-left: 10px;
    margin-bottom: 15px;
    padding-bottom: 10px;
    font-size: 16px;
    font-weight: 600;
    text-transform: uppercase;
    border-bottom: 1px solid lightgray;

    i {
      margin-left: 10px;
    }
  }

  .previous-product-table-data {
    .previous-product-con {
      padding: 0px;
      .title {
        padding-left: 15px;
      }
      .item {
        cursor: pointer;
      }
    }
    .OmniSwitch-Item {
      cursor: pointer;
    }
  }
}

#summaryView {
  .text {
    width: 100%;
    .summary-filter {
      display: flex;
      width: 60%;
      justify-content: flex-end;
      .filter-by-rep-name {
        width: 50%;
        margin-right: 20px;
      }
      .filter-by-select-container {
        width: 50%;
        margin-left: 20px;
      }
    }
    .summary-header,
    .message-header {
      display: flex;
      // justify-content: space-between;
      .summary-info {
        width: 40%;
      }
    }
  }
  .card .header {
    display: flex;
    .text {
      font-size: 16px;
      line-height: 1.5;
    }
    p,
    .text {
      margin-top: 5px;
      margin-left: 1%;
    }
  }
  th {
    color: black;
    font-weight: bold;
    cursor: pointer;
  }
  .view-all__label_color {
    color: #337ab7;
    cursor: pointer;
    font-size: 12px;
  }
  tbody {
    tr {
      td.retailer-name {
        display: flex;
        align-items: center;
        text-align: left !important;

        img {
          width: 30px;
          height: 20px;
          margin-left: 10px;
        }
      }
    }
  }
}
.Retailer-ContactForm {
  .Retailer-ContactForm__message {
    margin-top: 20px;
  }
  .Retailer-ContactForm__footer {
    margin-top: 30px;
  }
  .Retailer-ContactForm__message--label {
    text-transform: uppercase;
    margin-bottom: 15px;
  }
  .contact-subject {
    padding: 5px 15px;
    margin-top: 10px;
  }
  textarea {
    min-height: 132px;
  }
  .mdx-dropdown {
    .list-group-item {
      padding: 8px 15px;
      .rdw-search {
        height: 30px;
      }
    }
  }
  .save-reply-popover__container {
    min-width: 400px;
    top: -20px !important;
    left: -65px !important;
    .popover-title {
      background: #f7f7f7 !important;
      color: #000;
    }
  }
  .Retailer-ContactForm__footer {
    display: flex;
    .Retailer-ContactForm__footer__leftcol {
      flex-basis: 80%;
      .uploadblock {
        text-align: right;
      }
    }
    .Retailer-ContactForm__footer__rightcol {
      flex-basis: 20%;
      .Retailer-ContactForm__footer__rightcol-btn {
        padding: 8px 30px;
        color: #000;
      }
    }
  }
  .Retailer-ContactForm__errors {
    padding: 10px 15px;
  }
}
.flagged-reason {
  margin-bottom: 20px;
}
.flag-product-btn-container {
  margin-bottom: 20px;
}

.RetailerContactModal,
.FileUploaModal,
.ImageModal {
  .modal-dialog {
    min-height: calc(100vh - 60px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: auto;

    @media (max-width: $xs-screen) {
      min-height: calc(100vh - 20px);
    }
  }
}
.FileUploaModal {
  .modal-dialog {
    margin: 31px auto;
  }
}

.ImageModal {
  .modal-dialog {
    width: 80%;
    .modal-content {
      .modal-header {
      }
      .modal-body {
        div {
          align-items: center !important;
          img {
            max-width: 80%;
          }
        }
      }
    }
  }
}

.FileUploaModal,
.ImageModal {
  .modal-dialog {
    .modal-content {
      .modal-header {
      }
      .modal-body {
        > div {
          row-gap: 10px;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: center;

          textarea {
            height: 250px;
          }
        }
      }
    }
  }
}

.brand-sku-modal {
  .modal-dialog {
    width: 450px;
    min-height: calc(100vh - 200px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    .modal-title {
      font-size: 16px;
      font-weight: 700;
    }
    .modal-body {
      padding-left: 30px;
      padding-right: 30px;
      .brand-sku-options {
        .dropdown-indicator {
          color: #000;
        }
      }
    }
    .edit-brandsku-modal__form--margin {
      margin-bottom: 20px;
    }
    .edit-brandsku-modal__form__form_btn {
      margin-top: 35px;
      margin-bottom: 10px;
    }
    .edit-brandsku-modal__form__saveBtn {
      color: #000;
      font-weight: 700;
      color: #000;
      font-weight: 700;
      text-transform: uppercase;
      width: 100px;
      padding: 5px;
    }
    .edit-brandsku-modal__form__label {
      text-transform: uppercase;
      margin-bottom: 15px;
    }
  }
}
.SuccessModal {
  .modal-dialog {
    width: 375px;
    min-height: calc(100vh - 150px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: auto;

    @media (max-width: $xs-screen) {
      min-height: calc(100vh - 20px);
    }
  }
  p {
    font-size: 20px;
    font-weight: 600;
    text-transform: uppercase;
    margin-bottom: 20px;
    text-align: center;
  }
  .modal-header,
  .modal-footer,
  .modal-body {
    border: 0;
  }
}
.product-table-data {
  .export-btn {
    padding: 0px 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 10px;
    width: 100%;
    input[type="search"] {
      width: 45%;
    }
    button:last-child {
      color: $color-black;
      text-transform: uppercase;
      line-height: 20px;
      font-size: 14px;
      font-weight: 700;
      // margin-left: 49%;
    }
    button {
      border: 1px solid;
      border-color: #ddd #ddd;
      padding: 5px 10px;
      border-radius: 0;
      background-color: #fff;
      font-family: Roboto, sans-serif;
      font-size: 13px;
      margin: 0;
      line-height: 20px;
      text-transform: uppercase;
      font-weight: 700;
      color: #777;
    }
    button.active {
      background: #ebebeb;
      // color: white;
    }
  }
}

.email-history-record {
  display: flex;
  & > div {
    flex: 1;
  }
  margin-bottom: 10px;
}
.navbar-select-container {
  color: #555555;
}

.view-option {
  padding: 15px 30px;
  //border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.navbar {
  .omniconnect-search-form {
    max-width: 220px;
  }
}

@media (min-width: $xs-screen) {
  .regular-table-parent {
    margin-top: 15px;
  }
}

@media (max-width: $sm-screen) {
  .header-section {
    .viewing-period-mobile,
    .searchbar-mobile {
      width: 100%;
    }

    .viewing-period-mobile {
      .view-option {
        padding: 12px;
      }
    }
  }
}

@media (max-width: $xs-screen) {
  .sidebar-wrapper {
    a {
      text-align: left;
    }
  }
}
.Dashboard-Loader {
  text-align: center;
  position: relative;
  top: 35vh;
  .fa {
    width: auto;
  }
}

.RetailerView-Loader {
  text-align: center;
  position: relative;
  top: 20vh;
  .fa {
    width: auto;
  }
}

.whyToFlagForm {
  .comment-col {
    margin-top: 15px;
    label {
      padding-left: 0px;
    }
  }
  .whyToFlagForm__PriceDropdown {
    width: 30%;
    margin-bottom: 5px;
  }
  .field_wrapper {
    display: flex;
    .img-icon {
      width: 24px;
      height: 24px;
    }
    label {
      font-weight: normal;
      margin-left: 10px;
    }
    #price-label {
      font-weight: normal;
      margin-left: 10px;
    }
    .place-right:after {
      border-right-color: #acacac;
    }
  }
  .omni-form-field {
    label {
      font-weight: normal;
    }
    input[type="checkbox"] {
      display: inline-block;
      margin-right: 10px;
    }
  }
  .place-right:after {
    border-right-color: #acacac;
  }
}
#flagBrandProfileForm {
  .field_wrapper {
    display: flex;
    .img-icon {
      width: 24px;
      height: 24px;
    }
    label {
      margin: 0px 4px 0px 10px;
      font-weight: normal;
    }
  }
  .flagBrandProfileForm__dropdown {
    max-width: 50%;
    margin: 10px 0px;
  }
  .comment-col {
    margin-top: 15px;
    label {
      padding-left: 0px;
      font-weight: normal;
    }
  }
  .place-right:after {
    border-right-color: #acacac;
  }
}
.product-drilldown {
  .contact-retailer-btn {
    width: 60%;
    border-color: lightgray;
    text-transform: uppercase;
    font-weight: 600;
    border-radius: 3px;
  }
  .contact-retailer-btn:hover {
    border-color: lightgray;
  }
  .drill-contact-col {
    margin: 20px 0;
  }
}
.brandMatchingForm {
  .comment-col {
    margin-top: 15px;
    label {
      padding-left: 0px;
    }
  }
  .omni-form-field {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    label {
      flex: 1;
    }
    input {
      flex: 1;
    }
  }
}
#settingContainer {
  .form-control[disabled] {
    background-color: #b8b8b8;
  }
}
.RetailerProductsTable {
  tbody {
    text-align: center;
    .RetailerProductsTable-Row {
      td {
        label {
          font-size: 12px;
        }
      }
    }
  }
}
.card {
  .all-exports-list {
    max-height: 250px;
    overflow: auto;
    ul {
      list-style-type: none;
      padding-left: 20px;
      li {
        text-decoration: underline;
        a {
          font-weight: 700;
          color: #777777;
          font-size: 14px;
        }
      }
    }
  }
}

// media query for mobile new changes from maira
@media (min-width: $xs-screen) and (max-width: $sm-screen) {
  .summary-view-v2 {
    .summary-view-v2-inner {
      .page-layout-changer {
        .layout-btn {
          width: 12px !important;
          height: 11px !important;
        }
        .layout-btn:last-child {
          margin-right: 0px !important;
        }
      }
      .one-item-grid {
        min-height: 500px;
      }
      .two-item-grid {
        min-height: 400px;
      }

      .Image-Loader {
        .Image-Background {
          height: 300px;
          img {
            display: block;
            width: 100%;
            height: 300px;
          }
        }
      }
      .three-item-grid {
        min-height: 500px;
        .Image-Loader {
          .Image-Background {
            height: 220px;
            img {
              height: 220px;
            }
          }
        }
      }
    }
    .summary-view-filter-option {
      display: flex;
    }
  }

  #retailerView {
    .retailer-info {
      padding-top: 30px;
    }
  }
}

// for sidebar changes screen size upto $sm-screen
@media (max-width: $sm-screen) {
  #root .fab-icon {
    display: flex !important;
    position: fixed;
    width: 50px;
    height: 50px;
    align-items: center;
    justify-content: center;
    right: 30px;
    bottom: 30px;
    z-index: 1;
    border-radius: 50%;

    .fa {
      font-size: 24px;
      width: 24px;
    }
    &:focus {
      color: #fff;
    }
  }

  #login-modal {
    height: 60vh;
  }
  .contact-button {
    padding-top: 15px;
  }
  body {
    overflow-x: hidden;
  }
  /*
  #root .navbar .container-fluid .navbar-collapse {
    display: none !important;
  } */
  .sidebar {
    width: 0px !important;
    left: 0 !important;
    webkit-transform: translate3d(0px, 0, 0) !important;
    transform: translate3d(0px, 0, 0) !important;

    .logo-col,
    .siteNalysisLogo-header {
      display: none;
    }

    .header-right-menu {
      margin-top: 15px;
      justify-content: center;
      order: 3;
    }
    .visible-on-sidebar-regular {
      display: none !important;
    }
  }

  .sidebar-wrapper ul.nav {
    margin-top: 35px;
    display: flex;
    flex-direction: column;
    .searchbar-mobile {
      button {
        display: flex;
        align-items: center;
        padding: 4px 12px;
      }
    }
  }
  .nav-open .sidebar {
    width: 260px !important;
  }

  .nav-open .navbar-header .navbar-toggle {
    display: none !important;
  }

  .nav-open #root .main-panel {
    -webkit-transform: translate3d(0px, 0, 0) !important;
    transform: translate3d(0px, 0, 0) !important;
    left: 0px !important;
  }

  .main-panel {
    .navbar-style > div {
      padding: 10px !important;
      display: flex;
      flex-direction: column !important;
    }

    .navbar-xs-style {
      display: flex !important;
      justify-content: space-between;
      align-items: center;
      border-bottom: 2px solid;

      .logo-col {
        width: 100%;
        text-align: left;
        margin-bottom: 10px;
        .Image-Loader {
          text-align: left !important;
        }
        img {
          width: 60%;
        }
      }

      .siteNalysisLogo-header {
        width: 100%;
        .siteNalysisLogo-inner {
          margin: 0 auto;
        }
        img {
          width: 100%;
        }
      }

      button {
        margin-right: 0;
      }
    }
    .navbar-sm-style {
      margin-top: 10px;
      // padding-left: 10px;
      .navbar-header {
        display: flex;
      }
      .navbar-brand {
        margin: 0;
        padding: 0 5px 0 5px;
        height: auto;
        font-size: 15px !important;
        @media (max-width: 320px) {
          font-size: 12px !important;
        }
      }
      .navbar-toggle {
        padding: 0;
        margin: 5px 0 0 0;
      }
      .navbar-select-container {
        width: 200px;
        @media (max-width: 320px) {
          width: 180px;
        }
      }
    }
  }
}

@media (min-width: $xs-screen) and (max-width: $sm-screen) {
  .navbar-xs-style {
    .logo-col {
      padding-left: 10px;
      img {
        width: 90px !important;
      }
    }
    .siteNalysisLogo-header {
      width: 190px !important;
    }
  }
  .summary-view-v2 {
    .summary-view-v2-inner {
      .page-layout-changer {
        .layout-btn {
          width: 12px !important;
          height: 11px !important;
        }
        .layout-btn:last-child {
          margin-right: 0px !important;
        }
      }
      .one-item-grid {
        min-height: 500px;
      }
      .two-item-grid {
        min-height: 400px;
      }
      .three-item-grid {
        min-height: 500px;
      }
      .Image-Loader {
        .Image-Background {
          height: 300px;
          img {
            display: block;
            width: 100%;
            height: 300px;
          }
        }
      }
    }
    .summary-view-filter-option {
      display: flex;
    }
  }
  .navbar-sm-style {
    padding-left: 24px;
  }
  .main-panel {
    .siteNalysisLogo-header {
      .siteNalysisLogo-inner {
        width: 80%;
      }
    }
  }
}

@media (min-width: 320px) and (max-width: $xs-screen) {
  // summary page css

  .summary-view-v2 {
    .summary-view-v2-inner {
      margin: 0;
      h4 {
        font-size: 18px;
        margin-bottom: 0px;
      }
      .heading-con {
        font-size: 12px;
      }

      .image-info {
        width: 100%;
        margin-bottom: 30px;
      }

      .Image-Loader {
        .Image-Background {
          height: 330px;
          img {
            display: block;
          }
        }
      }

      .summary-view-filter-option {
        display: flex;
        flex-direction: column-reverse;
      }
    }
  }

  #summaryView {
    padding: 0;
    .container-fluid {
      padding: 0;
    }
    .summary-info {
      display: none;
      width: 40%;
    }

    .summary-header {
      justify-content: flex-end !important;
    }

    .card {
      .header {
        display: flex;
        flex-direction: column-reverse;

        .filter-by-select-container {
          .title-col {
            width: 20%;
          }
        }
      }
      .table-responsive {
        min-height: 500px;
        max-height: 700px;
        overflow: scroll;
        padding: 0;
      }
    }

    .omni-stat-cards {
      display: flex;
      flex-direction: column;
    }
  }

  #retailerView {
    padding: 15px 0 !important;
    .retailer-period-select-container {
      .select-col {
        width: 100%;
      }
    }
    .contact-retailer-btn-container {
      padding-left: 15px !important;
    }
    .RetailerProductsTableBody div {
      text-align: left !important;
    }
    .retailer-intro {
      .Thumbnail-Image {
        max-width: none;
        width: 100%;
        margin: 0 auto;
        height: 280px;

        .Image-Background-Cover {
          background: none !important;
          height: 160px !important;
          img {
            display: inline-block;
            max-width: 250px;
          }
        }
      }
    }

    .retailer-info {
      padding-top: 30px;
      // @media (max-width: 320px) {
      //   display: flex;
      // }
      .nav-tabs li a {
        padding: 4px 2px !important;
        h4 {
          font-size: 8px !important;
          // @media (max-width: 320px) {
          //   font-size: 8px !important;
          // }
        }
      }

      .has-feedback {
        input {
          margin-bottom: 15px;
        }
      }
    }
    .retailer-tab {
      .nav-tabs li a {
        padding: 5px 2px !important;
        h4 {
          font-size: 8px !important;
          // @media (max-width: 320px) {
          //   font-size: 8px !important;
          // }
        }
      }
      .product-table-data {
        div {
          padding: 0;
        }
        .export-btn {
          margin-bottom: 15px;
        }
        .card {
          padding: 15px;

          .table-responsive {
            overflow-y: scroll !important;
            height: 500px !important;
          }
        }
      }
    }
  }

  #RetailerProductView {
    .flag-product-btn-container,
    .contact-retailer-btn-container {
      display: inline-block;
      margin: 5px;
      .contact-retailer-btn,
      .product-flag {
        width: 100%;
      }
    }
  }
  .main-panel {
    .siteNalysisLogo-header {
      .siteNalysisLogo-inner {
        width: 80%;
      }
    }
  }
  .view-retailer-stats-btn {
    margin-top: 10px;
  }
}
