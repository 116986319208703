.Pagination {
  display: flex;
  .contact-retailer-btn {
    padding: 15px 0px;
    width: 20%;
  }
  .Pagination-Container {
    width: 80%;
    text-align: right;

    .pagination > li > a,
    .pagination > li > span,
    .pagination > li:first-child > a,
    .pagination > li:first-child > span,
    .pagination > li:last-child > a,
    .pagination > li:last-child > span {
      border-radius: 0;
    }
  }
}
