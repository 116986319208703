.users-tab__menu {
  .card {
    box-shadow: none;
    min-height: 80vh;
    margin-bottom: 15px;
    .content {
      padding: 0px;
      .table-responsive {
        padding: 0px !important;
      }
    }
    .header {
      padding: 15px 0px;
      .text {
        display: flex;
        justify-content: flex-end;
        .add-new-user-btn {
          width: 24%;
          text-align: center;
          padding: 0 0 0 5px;
          .card__btn {
            color: #000;
            text-transform: uppercase;
            font-weight: 700;
            max-width: 205px;
            width: 100%;
            border-color: #9b9191;
            padding: 5px;
            // margin-left: 40px;
          }
        }
      }
    }
    .users-table {
      border-collapse: unset;
      .users-table__body--loader-image {
        margin-bottom: 15px;
      }
      .ellipsis-con {
        cursor: pointer;
      }
      .users-table__header {
        .users-table__row {
          th {
            vertical-align: bottom;
            border-bottom: 1px solid #333 !important;
          }
          th {
            color: #000;
            font-weight: 700;
            cursor: pointer;
            padding: 15px 5px;
            text-align: left;
          }
          .username-header {
            width: 10%;
          }
          .emailid-header {
            width: 20%;
          }
          .role-header {
            width: 15%;
          }
          .assigned-retailers-header {
            width: 30%;
          }
          .actions-header {
            width: 25%;
          }
        }
      }
      .users-table__body {
        tr.users-table__row:hover {
          background-color: #fff !important;
        }
        tr.users-table__row:nth-of-type(odd) {
          background-color: #fff !important;
        }
        tr:last-child td {
          border-bottom: 1px solid #ddd;
        }
        .users-table__row {
          td {
            padding: 15px 5px;
          }
          .actions-btns {
            text-align: center;
            .delete-btn,
            .edit-btn {
              padding:5px 0;
              min-width: 100px;
              height: 30px;
              font-weight: 700;
              text-transform: uppercase;
            }
            .delete-btn {
              color: #e01c00;
              border-color: #e01c00;
              margin-bottom: 5px;
            }
            .edit-btn {
              color: #337ab7;
              margin-left: 10px;
              margin-bottom: 5px;
              border-color: lightgray;
            }
          }
        }
      }
    }
  }
  .pagination-container {
    padding: 20px 0;
    text-align: right;
    .pagination {
      margin: 0;
      li {
        display: inline-block;
        margin-bottom: 1px;

        a {
          border-radius: 0px;
        }
      }
    }
  }
}
.add-edit-user-modal {
  .modal-title {
    font-size: 18px;
    font-weight: bolder;
    color: #333;
  }
  .add-edit-user-modal__form {
    padding: 5px 20px 10px 20px;
    .add-edit-user-modal__form__errors {
      padding: 0px 0px 5px 0px;
    }
    .add-edit-user-modal__form__label {
      text-transform: uppercase;
    }
    .add-edit-user-modal__form__assignnedRetailerBtn {
      color: #000;
      border-color: #565656;
      font-weight: bold;
      text-transform: uppercase;
    }
    .add-edit-user-modal__form_btn{
      margin-bottom: 0px;
      margin-top: 30px;
      text-align: right;
    }
    .add-edit-user-modal__form__submitpart {
      display: flex;
      text-align: right;
      align-items: center;
      justify-content: flex-end;
    }
    .notify-block {
      input {
        margin-right: 5px;
      }
      label {
        font-weight: normal;
      }
    }
    .add-edit-user-modal__form__btns {
      margin-left: 10px;
      .add-edit-user-modal__form__createMesgBtn {
        color: #000;
        border-color: #565656;
        font-weight: bold;
        text-transform: uppercase;
      }
      .add-edit-user-modal__form__createMesgBtn:hover {
        color: #000;
        border-color: #000;
        font-weight: bold;
      }
      .add-edit-user-modal__form__updateMesgBtn {
        color: #000;
        border-color: #565656;
        font-weight: bold;
        text-transform: uppercase;
      }
      .add-edit-user-modal__form__updateMesgBtn:hover {
        color: #000;
        border-color: #000;
        font-weight: bold;
      }
    }
    .add-edit-user-modal__form--margin {
      margin-bottom: 30px;
      .css-vj8t7z {
        background-image: linear-gradient(to bottom,#fff,#e6e6e6);
      }
    }
    .modal-title {
      color: #000;
    }
    .add-edit-user-modal__form__label {
      color: #000;
    }
  }

}
#success-modal {
  .modal-header .close {
    margin-top: -10px;
  }
  .add-success {
    .modal-dialog {
      max-width: 410px;
    }
  }
  .update-success {
    .modal-dialog {
      max-width: 450px;
    }
  }
  .modal-body {
    padding: 10px;
    h5 {
      margin-top: 0px;
    }
  }
  .modal-backdrop.in {
    filter: alpha(opacity=50);
    opacity: 0.5;
  }
  .modal-backdrop {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1040;
    background-color: #000;
  }
  .modal-header {
    border-bottom: none !important;
    padding-bottom: 0px;
  }
  .fade.in {
    opacity: 1;
  }
  .modal-body {
    text-align: center;
    h4 {
      margin: 0px !important;
    }
  }
}
.assigned-retailers-modal {
  .modal-dialog {
    width: 1000px;
    height: 100%;
    display: flex;
    align-items: center;
  }
  .modal-title {
    h4 {
      font-size: 20px;
    }
  }
  .modal-content {
    margin: 0 auto;
  }
  .popup-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    .btn {
      margin-right: 20px;
      margin-top: 20px;
    }
  }
  .checkListForm__select-deselect-checkboxes {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    .isSelectAll {
      flex: 16%;
      input {
        margin-right: 5px;
      }
      label {
        color: #1b7804;
        font-weight: normal;
      }
    }
    .isDeselectAll {
      flex: 90%;
      input {
        margin-right: 5px;
      }
      label {
        color: #e01c00;
        font-weight: normal;
      }
    }
  }
}
#delete-user-modal {
  .modal-dialog {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
  }
  .modal-content {
    margin: 0 auto;
  }
  .modal-body {
    margin-top: 10px;
    text-align: center;
    padding-bottom: 10px;
  }
  .delete-user-modal__footer {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 10px;
  }
  .modal-footer {
    border-top: none;
    .yes-btn {
      margin-right: 5px;
    }
    .yes-btn,
    .no-btn {
      width: 75px;
      text-transform: uppercase;
      font-weight: bold;
      color: #000;
      padding: 4px 16px;
    }
    margin-bottom: 10px;
  }
  .delete-user-modal__text {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 0px;
  }
}
