#root {
  #home {
    background: #fff;
    .request__con,
    .learn__con {
      flex: 1;
      @media (max-width: 767px) {
        width: 100%;
      }
    }
    .request__con {
      margin-right: 10px;
      @media (max-width: 767px) {
        margin: 0 0 10px 0;
      }
    }

    #banner {
      border-bottom: 1px solid #d1d2e7;
      background-position: center;
      // padding: 3em;
      @media (max-width: 767px) {
        padding: 2em 2em 0 2em;
        background-position: right;
      }
      @media (min-width: 768px) and (max-width: 991px) {
        padding: 3em;
      }

      .banner__wrap {
        .banner__con {
          width: 45%;
          padding: 8em 4em 10em 4em;
          position: relative;
          top: -45px;
          .banner__img-wrapper {
            margin-bottom: 10px;
            @media (max-width: 767px) {
              margin-top: 15px;
            }
            img {
              max-width: 100%;
            }
          }
          .banner__text {
            margin-bottom: 30px;
            span {
              margin-right: 15px;
              @media (max-width: 767px) {
                margin-right: 4px;
              }
            }
            h2 {
              color: #2d3092;
              font-weight: bolder;
              font-size: 1.9vw;
              margin: 0 0 10px;
              @media (max-width: 320px) {
                font-size: 4.3vw;
              }

              @media (max-width: 767px) {
                font-size: 4.9vw;
                text-align: center;
              }

              @media (min-width: 992px) and (max-width: 1200px) {
                font-size: 1.9vw;
              }
              @media (min-width: 1265px) {
                font-size: 2.1vw;
              }
            }
            p {
              color: #2d3092;
              font-weight: 600;
              font-size: 2vw;
              @media (max-width: 767px) {
                font-size: 5.6vw;
              }

              @media (min-width: 992px) and (max-width: 1265px) {
                font-size: 2.2vw;
              }
              @media (min-width: 1265px) {
                font-size: 2.5vw;
              }
              @media (max-width: 320px) {
                font-size: 5vw;
              }
            }
          }
          .banner__demo-btns {
            display: flex;
            align-items: flex-start;
            flex-wrap: nowrap;
            justify-content: space-between;
            width: 80%;
            @media (min-width: 768px) and (max-width: 991px) {
              width: 90%;
            }
            @media (max-width: 767px) {
              width: 100%;
              flex-direction: column;
            }
          }

          .banner__spacer {
            border-top: 2px solid #000;
            margin: 40px 0;
            @media (max-width: 767px) {
              margin: 30px 0;
            }
          }
        }

        @media (max-width: 767px) {
          .banner__con {
            width: 100%;
            padding: 1em 0em 0em 0em;
          }
        }
        @media (min-width: 768px) and (max-width: 991px) {
          .banner__con {
            padding: 0em 5em 5em 1em;
          }
        }
        @media (min-width: 992px) and (max-width: 1264px) {
          .banner__con {
            padding: 5em 4em 10em 4em;
          }
        }
      }
    }

    #about-sitenalysis {
      border-bottom: 1px solid #d1d2e7;
      padding: 3.5em 10em 2em 10em;
      @media (max-width: 767px) {
        padding: 30px 15px;
      }
      @media (max-width: 1190px) {
        padding: 30px 15px;
      }
      .home__heading {
        padding-bottom: 2em;
        text-align: center;
        color: #818181;
        font-size: 30px;
        font-weight: 600;
        margin: 0;
        @media (max-width: 767px) {
          padding-bottom: 1em;
          font-size: 28px;
        }
      }
      .sitenalysis-reasons {
        display: flex;
        align-items: center;
        text-align: center;
        img {
          max-width: 100%;
        }
        @media (max-width: 767px) {
          display: block;
        }
        .text-wrapped {
          max-width: 450px;
        }

        p {
          margin: auto;
          line-height: 2.3;
          font-weight: 600;
          letter-spacing: 0.9px;
          @media (min-width: 768px) and (max-width: 991px) {
            margin: auto;
            line-height: 2;
          }
          @media (max-width: 767px) {
            font-size: 14px;
            line-height: 1.8;
          }
        }

        .adj-text-style {
          margin-top: 25px;
        }
        .sitenalysis-reasons-color-text {
          color: #2e3192;
          font-size: 24px;
          font-weight: 600;
          margin-top: 10px;
          @media (max-width: 767px) {
            font-size: 20px;
          }
        }
        @media (max-width: 767px) {
          .Image-Loader {
            img {
              width: 80%;
              margin-bottom: 0px;
            }
          }
        }
      }
    }

    #schedule-demo {
      padding: 15px;
      .recaptcha-margin-top {
        margin-top: 22px;
      }
      @media (min-width: 991px) {
        padding: 2em;
      }
      label {
        color: #575757;
      }
      .g-recaptcha {
        display: inline-block;
      }
      form {
        width: 90%;
        margin-left: auto;
        margin-right: auto;
        @media (min-width: 991px) {
          max-width: 40%;
        }
        @media (max-width: 768x) {
          width: 90%;
        }
        .form-group {
          label {
            font-weight: normal;
          }
          input,
          textarea,
          .rc-anchor-normal {
            border-radius: 0;
          }
        }
      }
      .contact-form-heading {
        margin-bottom: 1em;
        color: #818181;
        font-weight: 600;
        font-size: 30px;
      }
      .contact-form-text {
        margin-bottom: 2em;
        font-size: 14px;
        @media (max-width: 767) {
          font-size: 12px;
        }
      }
      .contact-form__contact-info {
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        // letter-spacing: 1px;

        .contactForm__phoneNo {
          border-right: 1px solid #474747;
          text-align: right;
          padding: 0 20px;
          @media (max-width: 767px) {
            border-right: 0;
            text-align: center;
          }
        }
        .contactForm__EmailId {
          text-align: left;
          padding: 0 20px;
          @media (max-width: 767px) {
            text-align: center;
          }
        }
        font-size: 20px;
        margin-top: 20px;
        color: #474747;
        div {
          a {
            color: #474747;
            font-size: 18px;
            @media (max-width: 767px) {
              font-size: 14px;
            }
          }
        }
      }
    }
  }
}
