@import 'assets/sass/_variables.scss';

.wrapper {
	.sidebar {
		#minimizeSidebar {
			top: 0;
			right: 0;
		}
		.logo-col {
			width: inherit;
			.Image-Loader {
				margin: 10px 0px;
			}
			a {
				color: $color-white;
				img {
					width: 60%;
				}
			}
		}
	}
}
.sidebar-mini {
	.wrapper {
		.sidebar {
			.logo {
				padding-top: 60px;
			}
			#minimizeSidebar {
				top: 15px;
				right: 15px;
			}
		}
	}
}