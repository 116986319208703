#inbox {
  .card {
    min-height: 80vh;
    margin-bottom: 15px;
    .content {
      padding: 0px 15px;
    }
    .header {
      display: flex;
      justify-content: space-between;
      .title {
        width: 70%;
        span {
          font-size: 16px;
        }
      }
      .text {
        text-align: right;
        width: 30%;
        .card__btn {
          color: #000;
          text-transform: uppercase;
          font-weight: 700;
        }
      }
    }
    .messages-table {
      border-collapse: unset;
      .messages-table__body--loader-image {
        margin-bottom: 15px;
      }
      .messages-table__header {
        .messages-table__row {
          th {
            color: #000;
            font-weight: 700;
            cursor: pointer;
            width: 30%;
            padding: 12px 20px;
          }
        }
      }
      .messages-table__body {
        .messages-table__row {
          td {
            text-transform: capitalize;
            padding: 12px 20px;
          }
          .actions-btns {
            text-align: center;
            .view-btn,
            .edit-btn {
              min-width: 100px;
              font-weight: 700;
            }
            .view-btn {
              color: #464faa;
              border-color: #464faa;
            }
            .edit-btn {
              color: #000;
              text-transform: uppercase;
              margin-left: 10px;
            }
          }
        }
      }
    }
  }
  .pagination-container {
    padding: 20px 0;
    text-align: right;
    .pagination {
      margin: 0;
      li {
        display: inline-block;
        margin-bottom: 1px;

        a {
          border-radius: 0px;
        }
      }
    }
  }
}
.add-edit-message-modal {
  .modal-dialog {
    @media screen and (min-width: 767px) {
      width: 650px;
    }
    .modal-content {
      padding: 10px;
    }
  }
  .add-edit-message-modal__form__errors {
    padding: 0px 0px 5px 0px;
  }
  .add-edit-message-modal__form__label {
    text-transform: uppercase;
  }
  .add-edit-message-modal__status {
    margin-top: 20px;
  }
  .form-footer-attach {
    display: inline-block;
  }
  .add-edit-message-modal__form_btn{
    margin-bottom: 0px;
    margin-top: 30px;
    text-align: right;
  }
  .add-edit-message-modal__form__createMesgBtn {
    color: #000;
    border-color: #565656;
    font-weight: bold;
    text-transform: uppercase;
  }
  .add-edit-message-modal__form__createMesgBtn:hover {
    color: #000;
    border-color: #000;
    font-weight: bold;
  }
  .add-edit-message-modal__form__updateMesgBtn {
    color: #000;
    border-color: #565656;
    font-weight: bold;
    text-transform: uppercase;
  }
  .add-edit-message-modal__form__updateMesgBtn:hover {
    color: #000;
    border-color: #000;
    font-weight: bold;
  }
  .add-edit-message-modal__form--margin {
    margin-bottom: 30px;
  }
  .modal-title {
    color: #000;
  }
  .add-edit-message-modal__form__label {
    color: #000;
  }
}
.save-reply-popover {
  .popover-content {
    width: 225px;
  }
}
.view-message-modal {
  pre {
    border: none;
  }
  .view-message-modal__con__label {
    text-transform: uppercase;
    font-size: 17px;
  }
  .message-status-info {
    p {
      font-size: 16px;
      margin-left: 5px;
    }
    .message-status-info__details {
      text-transform: capitalize;
    }
  }
  .message-content-info {
    .message-content-info__details {
      pre {
        white-space: initial;
        word-break: break-word;
        span {
          color: #333;
          background-color: inherit !important;
          font-size: 16px !important;
          font-family: inherit !important;
        }
      }
    }
  }
}