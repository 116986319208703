.notification {
  .notificationListItem {
    padding: 5px 10px 2px 10px;
    background: #d3d3d36b;
    margin-bottom: 15px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    .arrow-icon {
      font-size: 24px;
    }

    .notification-subject {
      p {
        margin: 0;
        font-size: 12px;
      }
    }
    .notification-subject_created {
      font-size: 11px;
      display: flex;
      justify-content: center;
      flex-direction: column;
      text-align: center;
    }
    .alert {
      margin-top: 50px;
    }

    .leftContent,
    .rightContent {
      display: flex;
      .file-text {
        padding: 0px 8px 2px 8px;
        strong {
          font-size: 15px;
        }
      }

      .item {
        padding: 3px 8px 0px 8px;
        margin-left: 15px;
        // background: #c0c0c0;
        background: #8b818163;
        font-size: 12px;
        text-transform: capitalize;
        border-radius: 7px;
      }

      .require_review {
        background: #bd072f;
        color: white;
      }
      .caution {
        background: #ffff00;
      }
      .unflagged {
        background: #8b818163;
      }
      /*  .fileNameColor {
         
        } */
    }

    .rightContent {
      .item {
        margin-right: 30px;
        background: #4000ff41;
        color: white;
      }
      .rejected,
      .approved,
      .pending {
        margin-right: 30px;
        text-transform: uppercase;
        font-size: 12px;
        padding: 4px 8px 0px 8px;
        color: white;
        border-radius: 7px;
      }
      .rejected {
        background: #e11b28;
      }
      .approved {
        background: #009e0e;
      }
      .pending {
        background: #8b818163;
        color: #333;
      }
    }
  }
  .isSeen {
    background: #8b818163;
  }
  .pagination > li:hover > a:hover,
  li.active > a {
    color: #fff;
    background-color: #c0c0c0 !important;
  }
}

.NotificationPopUpModal {
  .notification-message {
    margin-top: 20px;
  }
  .modal-header-con {
    border-bottom: 0px;
    padding-bottom: 0px;
  }
  .modal-title {
    font-size: 18px;
    font-weight: 700;
  }
  .modal-subject {
    font-size: 12px;
    font-weight: 600;
  }
  .modal-body {
    padding-top: 5px;
  }
  .notification-date {
    text-align: right;
    .created-date {
      font-size: 10px;
    }
  }
}
.notificationListItem:hover {
  background: #c2bcbc;
}
