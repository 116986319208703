#root {
	.omni-stat-cards {
		display: flex;
		h5 {
			border-bottom: 1px solid #000;
			padding-bottom: 10px;
			margin: 0;
		}
		.stats-value-col {
			text-align: center;
			span {
				font-size: 35px;
			}
		}
		p {
			padding-top: 10px;
			text-transform: capitalize;
		}
	}
}