#root {
  .product-screens-container {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding-bottom: 30px;
    .Image-Loader > img {
      max-height: 300px;
      width: 100%;
    }

    img {
      max-width: 300px;
			height: 280px;
    }
    .Image-Background {
      height: 300px;
    }
    .product-screens-r1 {
      display: grid;
      grid-template-columns: 25% auto;
      grid-row-gap: 10px;
      margin-bottom: 30px;
    }
    @media (min-width: 992px) {
      .product-screens-r1 {
        grid-template-columns: 13% auto;
      }
    }
  }
  @media (max-width: 992px) {
    .product-screens-container {
      padding: 15px;
    }
  }
}
